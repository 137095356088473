import React, { useEffect, Fragment,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
//24-march-2023 for acs desc
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import useWindowDimensions from "../../useWindowDimensions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from "@material-ui/core/Checkbox";

import MenuItem from '@material-ui/core/MenuItem';


// import Grid from "@material-ui/core/Grid";
// import image from "../../images/A1 Wholesale/01 Heading.png"
import image from "../../images/A1 Wholesale/HeadingNew.jpeg"



import HelpIcon from '@material-ui/icons/Help';
import DescriptionIcon from '@material-ui/icons/Description';

import { isMobile } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import { NavLink } from "react-router-dom";
import { Link,useHistory } from "react-router-dom";



import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';
// import Invoice from './PDF/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from '@date-io/date-fns';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
// import CovidTemplate from './Covid_PDF/CovidTemplate'
// import TemplateCertificate from './TemplateCertificate/TemplateCertificate'
import GroupIcon from '@material-ui/icons/Group';
// import CertificateTemplate from './Certificate/CertificateTemplate'

// import SignCovidTemplate from './Sign_Covid_PDF/SignCovidTemplate'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import Covid_AntiGen from './Covid_AntiGen/CovidTemplate'
import { bool } from 'prop-types';
import { ProductContex } from '../../ContextStore.js';



import TextField from '@material-ui/core/TextField'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id = localStorage.getItem('patient_name') + ""





const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

export default function Lab_Tests({}) {
  const {dispatcchUserEvents,billAmount,cartItems,TotalAmount,TotalDiscount,TotalPayable,TotalPayableAddDisc,TotalRfdAmount,TotalRfdPayable,TotalRfdPayableAddDisc,TotalRefundDiscountAmount} = useContext(ProductContex);
  var from = new Date();

  const classes = useStyles();
  const { width } = useWindowDimensions();

  //for count data 
  const [count,setcount]=React.useState(1)


  //client list 
  const [ClientList,setClientList]=React.useState([])
  // this is for client selection
  const [open,setOpen]=React.useState(false)
   // this is for Previous Invoice Selection
   const [openPreInvoice,setopenPreInvoice]=React.useState(false)
  const [fromdate, setFromDate] = React.useState(from)
  const [todate, setToDate] = React.useState(from)
  const [ifPeriodApplyYes, setifPeriodApplyYes]= React.useState(true)
  const [ifPeriodApplyNo, setifPeriodApplyNo]= React.useState(false)
  const [PreInvoiceData, setPreInvoiceData]= React.useState([])
  // for other variable
  const [invoiceId,setInvoiceId]=React.useState("")
  const [branchId,setbranchId]=React.useState("")
  const [invoiceDate,setinvoiceDate]=React.useState("")

  const [clientId,setclientId]=React.useState("")
  const [clientTitle,setclientTitle]=React.useState("")

  const [noRecordFound,setnoRecordFound]=React.useState(false)
  // for product list autocomplete jab tak prev invoice select na ho ye na chaly
  const [ProductListempty, setProductListempty] = React.useState([{
    item_name:"Please Select Previous Invoice ! "
  }])
  const [loading,setLoading]=React.useState(false)

  const history = useHistory();




  useEffect(()=>{
console.log("yess")
if(localStorage.getItem('user_id')  ){
  // window.location.href="https://mpl-labs.pk"
  
 }
 else{
  history.push('/Login');  
 }
  },[cartItems])

  
  

  useEffect(()=>{
dispatcchUserEvents("ClearData")


    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientsGenListCallingUp_api",

    // {
    //   method:"POST",
    //   'Content-Type':"application/json",
    //   body: JSON.stringify({
    //     // branch_id:1,
    //     branch_id:parseInt(localStorage.getItem('branch_id')),

    //     user_id:localStorage.getItem('user_id')

    //   })
    // }).then((res) => res.json()).then((response) => {
    // // console.log("check Clients data "+JSON.stringify(response))  
    // // setClientList(response)
    // var data = [];
    // response.map((item) => {
    //   // console.log(item)
     
    //   var obj = {
    //     clientTitle: item.arr_cl_name,
    //     phone: item.arr_phone,
    //     city: item.arr_city,
    //     state: item.arr_state,
    //     zip: item.arr_zip,
    //     address: item.arr_address,

    //     select: (
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         style={{
    //         backgroundColor:"#cb1a2d"

    //         }}
    //         className={classes.button}
    //         onClick={() => {
    //           setclientId(item.arr_cl_id)
    //           setclientTitle(item.arr_cl_name)
             
    //       setOpen(false)
    //         }}
    //       >
    //         Select
    //       </Button>
    //     ),
    //   };

    //   data.push(obj);
    // });
    // setClientList(data);
 

    // }).catch((err)=>{
    //   console.log("err "+err)

    //  alert("Session Expired "+err)
    //  localStorage.clear()

    // })



   
  },[])
 
  
  return (
    <>
      <Paper className={classes.root} style={{}}>
      {/* <h1 style={{color:"red"}}> hshshsahhdfahdsajhsdfagh</h1> */}
        <div style={{ padding: '1em'}}>
        {width<500?
          <div onClick={()=>{
            // history.push('/');   
          }} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={image} 
style={{height:80,width:width<500?300:400}}
 ></img>
 </div>
 :<></>}
 
<GridContainer style={{ padding: '2em' }}>
<GridItem xs={12} sm={6} md={3} lg={3} style={{}}>

<FormGroup style={{
              marginLeft:width<500?"": "5em"
            }}>
              <b style={{fontWeight:"bold"}}>Period Apply</b>
              <FormControlLabel
              labelStyle={{color: 'red'}}
                control={<Checkbox color="success" style ={{
                  color: "#268ae1",
                }}checked={ifPeriodApplyYes} onChange={(e) => {
                  setifPeriodApplyYes(e.target.checked)
                  if (e.target.checked) {
                   setifPeriodApplyNo(false)
                   setnoRecordFound(false)
                  }
                }} />}
                label="Yes"
              />
              <FormControlLabel
                control={<Checkbox color="success"  style ={{
                  color: "#268ae1",
                }} checked={ifPeriodApplyNo} onChange={(e) => {
                  setifPeriodApplyNo(e.target.checked)
                  if (e.target.checked) {
                   setifPeriodApplyYes(false)
                   setnoRecordFound(false)

                  }
                }} 
                />}
                label="No"
              />
            
            
              
            </FormGroup>
</GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{marginTop:width<500?"4%":width<600?"2%":0}}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="MM/dd/yyyy"
                value={fromdate}
    maxDate={from}

                InputAdornmentProps={{ position: "start" }}
                onChange={date => {
                  setFromDate(date)
                  setnoRecordFound(false)
                }
                }
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{marginTop:width<500?"4%":width<600?"2%":0}}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
  <KeyboardDatePicker
    variant="inline"
    inputVariant="outlined"
    label="to"
    format="MM/dd/yyyy"
    value={todate}
    maxDate={from}
    InputAdornmentProps={{ position: "start" }}
    onChange={date => {setToDate(date)
      setnoRecordFound(false)
    
    }
  }
  />
</MuiPickersUtilsProvider>
</GridItem>
<GridItem xs={12} sm={6} md={3} lg={3} style={{marginTop:width<500?"4%":width<600?"2%":0}}>


<Button
           variant="contained"
           color="primary"
           onClick={() => {
            // start #############
      // const test=[{invoice_no:"",branch_id:1,invoice_date:"2024-01-18T10:28:58.265Z",invoice_remarks:"",user_id:"04",user_name:"DJ",client_id:"01CL00724",client_title:"1 10 MART ",inv_amount:50.97,inv_discount_value:0,inv_discount_special_percent:0,inv_discount_special_value:0,invoice_net_value:50.97,refund_amount:0,refund_line_discount:0,refund_special_discount_percent:0,refund_special_discount_value:0,refund_net_value:0,ArrEntrySequence:[1,2,3],ArrSequenceNo:[1,2,3],ArrItemSystemID:[605,3825,2892],ArrItemCode:["","",""],ArrItemName:[" T-Shirts Plain (1/6ct)","Baked Candles Terpene Infused 13oz (1ct)","Blunt Effects AF Spray (1/18ct)"],ArrItemQty:[1,1,1],ArrItemRate:[14.99,2.49,33.49],ArrItemAmount:[14.99,2.49,33.49],ArrItemUnitCost:[14.99,2.49,33.49],ArrDiscountPercent:[0,0,0],ArrDiscountValue:[0,0,0],ArrRefundQty:[0,0,0],ArrRefundValue:[0,0,0]}]
      const test=[{"invoice_no":"","branch_id":1,"invoice_date":"2024-01-18T10:59:45.321Z","invoice_remarks":"","user_id":"04","user_name":"DJ","client_id":"01CL00724","client_title":"1 10 MART ","inv_amount":21.47,"inv_discount_value":0,"inv_discount_special_percent":10,"inv_discount_special_value":2.15,"invoice_net_value":19.32,"refund_amount":0,"refund_line_discount":0,"refund_special_discount_percent":0,"refund_special_discount_value":0,"refund_net_value":0,"ArrEntrySequence":[1,2,3],"ArrSequenceNo":[1,2,3],"ArrItemSystemID":[605,145,2611],"ArrItemCode":["","",""],"ArrItemName":[" T-Shirts Plain (1/6ct)"," TP GP 459510 ( 1CT )-ODR","10mm China male Slider 3.5\" ( 1CT )-ODR"],"ArrItemQty":[1,1,1],"ArrItemRate":[14.99,4.99,1.49],"ArrItemAmount":[14.99,4.99,1.49],"ArrItemUnitCost":[14.99,4.99,1.49],"ArrDiscountPercent":[0,0,0],"ArrDiscountValue":[0,0,0],"ArrRefundQty":[0,0,0],"ArrRefundValue":[0,0,0]}]
      
      // end ####################
      setnoRecordFound(false)

            var obj={
              // branch_id:parseInt(localStorage.getItem('branch_id')),

              // // branch_id:2,
              // user_id:localStorage.getItem('user_id'),
              // // user_id:"11",
        
              // date_from:fromdate.toISOString(),
              // date_till:todate.toISOString(),
              // if_period_apply:ifPeriodApplyYes?true:false
              user_type:localStorage.getItem('user_type'),

              // branch_id:2,
              user_id:localStorage.getItem('user_id'),
              // user_id:"11",
        
              date_from:fromdate.toISOString(),
              date_till:todate.toISOString(),
              if_period_apply:ifPeriodApplyYes?true:false
            }
            console.log("check obj "+JSON.stringify(obj))
            // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingList",
            fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_INV_ModifyListCalls",

            {
              method:"POST",
              'Content-Type':"application/json",
              body: JSON.stringify(obj)
            }).then((res) => res.json()).then((response) => {
            console.log("check Products response "+JSON.stringify(response)) 
            if (response[0].invoice_id!=""){
            var DataInvoice=[]
            response.map((item) => {
              // console.log(item)
             
              var obj = {
                invoice_id: item.invoice_id,
                status_pay:item.status_pay,
                invoice_date:(new Date(item.invoice_date).getMonth() + 1) + "/" + (new Date(item.invoice_date).getDate())+ "/" + (new Date(item.invoice_date).getFullYear()) ,
                client_title: item.client_title,
                invoice_net_value: parseFloat(item.invoice_net_value).toFixed(2)+"$",
                invoice_remarks: item.invoice_remarks,
                balance_value:item.balance_value.toFixed(2)+"$",
                received_value:item.received_value.toFixed(2)+"$",
               
                select: (
                  <Button
                    variant="contained"
                    color="success"
                    style={{
                      backgroundColor:"#268ae1",
                    color:"white"
        
                    }}
                    className={classes.button}
                    onClick={() => {

                    //  alert("hy")
                   
                    var userId = localStorage.getItem('user_id');

          window.open('https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_INV_DownloadsPDF_api?user_id='+userId+'&invoice_id='+item.invoice_id)

                   
// var invoiceId = "03-INV-01475";
// var obj={
//   user_id:"04",
//   invoice_id:"03-INV-01475",

// }

// Construct the request parameters
// var params = new URLSearchParams();
// params.append("invoice_id", invoiceId);
// params.append("user_id", userId);
// var apiUrl = "https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceDownloadingPDF_api";
// var apiUrl = "https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceDownloadingPDF_api";


                    // fetch(apiUrl, {
                    //   method: "POST",
                    //   'Content-Type': 'application/x-www-form-urlencoded',
                    //   body: JSON.stringify(obj)
                    // })
                    // .then(response => response.arrayBuffer())
                    // .then(buffer => {
                    //   // Create a Blob URL for the PDF content
                    //   var blob = new Blob([buffer], { type: 'application/pdf' });
                    //   var pdfUrl = URL.createObjectURL(blob);
                  
                    //   // Open the PDF link in a new window
                    //   window.open(pdfUrl, '_blank');
                    // })
                    // .catch(error => console.error('Error fetching PDF:', error));
                    // var userId = "04";
// var invoiceId = "03-INV-01475";
                    // var pdfUrl = "https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceDownloadingPDF_api?user_id=04&invoice_id=03-INV-01475";
                    // var pdfUrl = "https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceDownloadingPDF_api?user_id=" +encodeURIComponent(userId) +"&invoice_id=" + encodeURIComponent(invoiceId) ;
                    // window.open(pdfUrl, '_blank');
                    // var obj={
                    //   invoice_id:"83-INV-00002",
                    //   user_id:"KS",
                    // }
                    // console.log("check pdf object "+JSON.stringify(obj))
                            // window.open('https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceDownloadingPDF_api?user_id=04&invoice_id=03-INV-01475')        
          // fetch("https://4dserver.jtelwholesale.com:443/4DACTION/WebInvoiceDownloadingPDF_api",
          // {
          //   method:"POST",
          //   'Content-Type':"application/json",
          //   body: JSON.stringify(obj)
          // }).then((res) => res.json()).then((response) => {
          //   console.log("check pdf "+response)
          // })
          // window.open('https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceDownloadingPDF_api?user_id='+userId+'&invoice_id='+item.invoice_id)
        
          // const fetchData = async () => {
          //   const url = 'https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceDownloadingPDF_api';
      
          //   // Create an object with the parameters
          //   const params = {
          //     user_id: '04',
          //     invoice_id: '03-INV-01475',
          //   };
      
          //   // Convert the object to a query string
          //   const queryString = Object.keys(params)
          //     .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          //     .join('&');
      
          //   // Combine the URL with the query string
          //   const fullUrl = `${url}?${queryString}`;
      
      //       try {
      //         const response = await fetch(fullUrl);
      // console.log("check respone"+response)
      //         if (response.ok) {
      //           // Assuming the response is a blob, you can handle it accordingly
      //           const blob = await response.blob();
      // console.log("check blob"+blob)

      //           // Do something with the blob, e.g., save the PDF
      //           // ...
      
      //         } else {
      //           console.error(`https error! Status: ${response.status}`);
      //         }
      //       } catch (error) {
      //         console.error('Fetch error:', error);
      //       }
          // };
      
          // fetchData();         
                    }}
                  >
                    PDF
                  </Button>
                ),
              };
        
              DataInvoice.push(obj);
            });
            
            setPreInvoiceData(DataInvoice)
          }else{
            setnoRecordFound(true)
          }
        
            })
       
           
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "14.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#268ae1"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
<SearchIcon  style={{width:20,height:20,color:"white",marginRight:5}}></SearchIcon >

             Search
           </Button>
          {noRecordFound &&  <h4><b style={{color:"red"}}>No Invoice Found</b></h4>}
</GridItem>





         </GridContainer>
         <GridContainer style={{  }}>
<GridItem xs={12} sm={12} md={12} lg={12} style={{}}>
       
          <MaterialTable
            columns={[
              { field: "select", title: "Download" },
              { field: "invoice_id", title: "InvoiceId",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_id}</p>
              }
             },
             { field: "status_pay", title: "Status",
              render: rowData => {
                // return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.status_pay}</p>
                const backgroundColor = rowData.status_pay === "Paid-Off" ? "green" :"red";
return (
<div style={{ backgroundColor, color: "white", padding: "8px",  textAlign: "center" }}>
{rowData.status_pay}
</div>
);
              }
             },
              { field: "invoice_date", title: "Date",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_date}</p>
              }},
              { field: "client_title", title: "Client Title",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.client_title}</p>
              } },
              { field: "invoice_net_value", title: "Net Value",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_net_value}</p>
              } },
              { field: "received_value", title: "Recieved Value",
                render: rowData => {
                  return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.received_value}</p>
                } },
                { field: "balance_value", title: "Balance Value",
                  render: rowData => {
                    return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.balance_value}</p>
                  } },
              { field: "invoice_remarks", title: "Remarks",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_remarks}</p>
              } },
             



            ]}
            style={{
              // marginLeft:width<960?0: 10,
              border:" 2px solid #268ae1",
              
            }}
            options={{
              // paging: false,
              headerStyle: {
                backgroundColor:"#268ae1",
                color: "#FFF",
                // fontSize: "17px",
                // textAlign: "center",
                fontWeight: "bold",
                // minHeight:"50em"
              },
            
              // rowStyle: rowData => {
              //   // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
              //   //   return {};
              //   // }
              //   // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
              //   return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
              // };
              // }
            }}
            data={PreInvoiceData}
            // title={"Invoice PDF"}
            title={<span style={{ color: '#268ae1',fontWeight:"bold" }}>Invoice PDF</span>}

          />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"end",alignItems:"center",marginTop:10}}>
      <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
            //  SeveRecordHandler()
            // alert("check data "+remarks+from.toISOString())
            // window.location.reload();
dispatcchUserEvents("ClearData")
            
history.push("/")
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "12.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#268ae1"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
            Back to Home
           </Button>
           </GridItem>
           </GridContainer>

        </div>
        
       
       


      </Paper>
     

      {/* for prev invoice selection */}
     

    </>
  );
}
