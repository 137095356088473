// import React, { useEffect } from "react";
import React,{useState,useContext,useEffect}from 'react'
import { ProductContex } from '../../ContextStore';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { Link ,useHistory} from "react-router-dom";
import image1 from "../../images/MM/GlobalDisctributorsLogo.png";
import image2 from "../../images/MM/User_icon.jpg"
import imagefooter from "../../images/A1 Wholesale/02 Address Lines.png"

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';


// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Close from "@material-ui/icons/Close";

import IconButton from "@material-ui/core/IconButton";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import TextField from '@material-ui/core/TextField';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";

import Input from '@material-ui/core/Input';

import url from '../../link'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import useWindowDimensions from "../../useWindowDimensions";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

// import image from "assets/img/building.jpeg";
import image from "../../images/A1 Wholesale/HeadingNew.jpeg"

import { isMobile } from 'react-device-detect';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);
  
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function LoginPage(props) {

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const {dispatcchUserEvents,billAmount,billAmount2} = useContext(ProductContex);

  const [classicModal, setClassicModal] = React.useState(false);
  const [email, setEmail]= React.useState('')
  const [password, setPassword]= React.useState('')
  const classes = useStyles();
  const [role, setRole]= React.useState('client')
  const [loginType, setloginType]= React.useState('name')
  const { width } = useWindowDimensions();

  const [ServerResponse, setServerResponse]= React.useState('')
  const { ...rest } = props;
  const [branches,setBranches]=React.useState([])
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    newpassword:'',
    shownewpassword:false,
    confirmPass:'',
    showConfrimPass:false

  });
  const history = useHistory();
  const handleClickShowConfrimPass = () => {
    setValues({ ...values, showConfrimPass: !values.showConfrimPass });
  };

  const handleMouseDownConfrimPass= (event) => {
    event.preventDefault();
  };

  useEffect(()=>{
    if(localStorage.getItem('user_id')  ){
      // window.location.href="https://mpl-labs.pk"
      history.push('/Home');  
     }
      //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
      //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
       
      // setBranches(response)
         
      //  })
       window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    
  },[])
  return (
    <div style={{backgroundColor:"white",position:"absolute",width:"100%",}}>
     {width<500?<></>:
      <Header
      
        // absolute
        
        color="transparent"
        brand="Material Kit React"
        login="yes"
        rightLinks={<HeaderLinks />}
        {...rest}
        // height=""
      />
     }
          <div style={{height:"100%",width:"100%",display:"flex",flexDirection:"column",position:"fixed",backgroundColor:"#268ae1"}}>

<div style={{height:width<500?"98%":"83%",width:width<500?"98%":"99%",display:"flex",flexDirection:"column",position:"fixed",backgroundColor:"white",marginLeft:width<500?"1%":"0.5%",marginRight:width<500?"1%":"0.5%",marginTop:width<500?"2%":"0.5%"}}>
<div style={{height:"15%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
<img src={image} 
style={{height:width<500?80:100,width:width<500?300:350,marginTop:width<500?0:20}}
 ></img>
</div>
{/* whole screen start */}
<div style={{height:"80%",width:"100%"}}>
 {/* first portion image and name */}
<div style={{height:"10%",width:"100%",display:"flex",flexDirection:"row"}}>
{/* <div style={{height:"100%",width:width<500?"40%":"45%",display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
<img src={image2} style={{height:80,width:100}} ></img>
 </div> */}
 <div style={{height:"90%",width:width<500?"60%":"55%",display:"flex",justifyContent:"center",alignItems:"flex-start",flexDirection:"column",marginTop:"2%"}}>
<div>
 <p style={{fontFamily:"cursive",fontSize:25,color:'white'}} > Login Here !</p>
 </div>
 
 </div>
</div>
{/* 2nd portion buttons */}

<div style={{height:"80%",width:"100%",display:"flex",flexDirection:"column",}} >
 <div style={{height:"15%",width:width<500?"90%":"30%",marginLeft:width<500?"5%":"35%",marginRight:width<500?"5%":"35%", display:"flex",justifyContent:"center",alignItems:"center"}}>
  <div style={{width:"30%"}}> <p style={{color:"#268ae1",fontWeight:"bold"}} >You wanna login as</p></div>
 <FormControlLabel
                //  style={{marginLeft:"1em"}}
                style={{width:"30%"}}

                 control={<Checkbox color="success" style={{color:"#268ae1"}} checked={role==="client"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  setRole("client")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Retailer</Typography>}
               />
              
                <FormControlLabel
                //  style={{marginLeft:"1em"}}
                style={{width:"30%"}}

                 control={<Checkbox color="success" style={{color:"#268ae1"}} checked={role==="user"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  // setloginType(e.target.value)
                  setRole("user")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Admin</Typography>}
               />
   </div>
   <div style={{height:"15%",width:width<500?"90%":"30%",marginLeft:width<500?"5%":"35%",marginRight:width<500?"5%":"35%",display:"flex",justifyContent:"center",alignItems:"center"}}>
   <FormControlLabel
                  style={{width:"30%"}}
                //  style={{marginLeft:"1em"}}
                 control={<Checkbox color="success" style={{color:"#268ae1"}} checked={loginType==="Phone"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  setloginType("Phone")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Phone</Typography>}
               />
              <FormControlLabel
                 style={{width:"30%"}}
                 control={<Checkbox color="success"  style={{color:"#268ae1"}} checked={loginType==="email"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  // setloginType(e.target.value)
                  setloginType("email")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Email</Typography>}
               />
               
                   <FormControlLabel
                   style={{width:"30%"}}
                //  style={{marginLeft:"1em"}}
                 control={<Checkbox color="success" style={{color:"#268ae1"}}  checked={loginType==="name"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  setloginType("name")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Login ID</Typography>}
               />
   </div>
   <div style={{height:"15%",width:width<500?"90%":"30%",marginLeft:width<500?"5%":"35%",marginRight:width<500?"5%":"35%",display:"flex",justifyContent:"center",alignItems:"center"}}>

   <TextField required id="" block
                  fontColor="red"
                  // style={{borderBottom:"2px solid red"}}
                  inputProps={{ autoComplete: 'off' }}
                  fullWidth={true}
                  label="UserName / ID"
                  value={email}
                                  InputLabelProps={{ style: { color: '#767879' ,fontWeight:"bold"} }}

                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }} 
                  // InputProps={{
                  //   classes: {
                  //     underline: "red",
                  //   },
                  // }}
                  // InputLabelProps={{
                  //   style: { color: 'red' },
                  // }}
                  />
   </div>
   <div style={{height:"15%",width:width<500?"90%":"30%",marginLeft:width<500?"5%":"35%",marginRight:width<500?"5%":"35%",display:"flex",justifyContent:"center",alignItems:"center"}}>
   <TextField required 
                    id="password" block
                  fullWidth={true}
                  label="Password"
                  value={password}
                  type={values.showConfrimPass ? 'text' : 'password'}
                  InputLabelProps={{ style: { color: '#767879' ,fontWeight:"bold"} }}
                  inputProps={{ autoComplete: 'off' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfrimPass}
                        onMouseDown={handleMouseDownConfrimPass}
                      >
                        {values.showConfrimPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e)=>{
                    setPassword(e.target.value)
                  }} 
                  
                  />
   </div>
   <div style={{height:"20%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",}}>
   <Button className="loginButton" color="#196c90" size={width<500?"sm":"lg"} style={{backgroundColor:'#268ae1',width:width<500?"30%":"10%"}}
                     onClick={() => {
                      localStorage.clear();
                      // localStorage.setItem('user_id',"00001")
                      // localStorage.setItem('user_name',"aamir");
                  // history.push('/Home'); 
                       if(email.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Please Enter Login Name !!')
                        return
                    
                       }
                       if(password.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Please Enter Password !!')
                        return
                    
                       }
                       console.log("check login value ")
                       console.log(email)
                       console.log(password)
                       console.log(role)
                       console.log(loginType)

                      //  var log='ID'
                      // example of JTell https://4dserver.jtelwholesale.com/4DACTION/
                      // fetch('https://74.208.115.190/4DACTION/WebMobAppS_UserLogin',{
                      fetch('https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_UserLogin',{


                    
                      method: 'POST',
                        'Content-Type':"application/json",
                        body: JSON.stringify({
                          login_role:role,
                          login_type:loginType,
                          login_id:email,
                          login_password:password
                        })
                      }).then((res)=>res.json())
                      .then( async(response)=>{
                        console.log(JSON.stringify(response))
                                  

                        var con=false

                        if(response.if_found){
                          
                          localStorage.setItem('user_id',response.my_id)
                         
                          localStorage.setItem('if_admin',response.if_admin)
                          localStorage.setItem('user_type',response.my_type)
                          localStorage.setItem('user_name',response.my_name)
                         if(response.my_type=="client")
{
  localStorage.setItem('client_id',response.my_id)
}
// dispatcchUserEvents("LoginCred",{token:response[0].valid_token,branchid:response[0].branch_id,userId:response[0].user_id,userName:response[0].user_name,adminRight:response[0].admin_rights})
                      history.push('/Home');              

                          
}
else{
  // alert("Please Enter Valid Credentials")
  setClassicModal(true)
  setServerResponse('Please Enter Valid Credentials !!')
}

                      })
                                  
                      }}
                    >
                      Login
                    </Button>
   </div>
   <div style={{height:"20%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",}}>
   <img src={imagefooter} 
   style={{height:width<500?50:80,width:width<500?300:400}}
    ></img>
   </div>
</div>
</div>
{/* <div style={{height:"15%",width:"100%",backgroundColor:"red"}}>a</div> */}

{/* <div style={{height:"20%",width:"100%",backgroundColor:"red"}}>a</div>
<div style={{height:"20%",width:"100%",backgroundColor:"green"}}>b</div>
<div style={{height:"20%",width:"100%",backgroundColor:"red"}}>a</div> */}





</div>
</div>
      {/* <div
        className={classes.pageHeader}
        style={{
          // backgroundImage: "url(" + image + ")",
          // backgroundSize: "cover",
          // backgroundPosition: "center bottom",
          backgroundColor:width<500?"white":"#e3e3e3"
        }}
      >
        <div 
        className={classes.container} 
        style={{
          paddingBottom:width<500?"35px":"20px",
          // backgroundColor:"#268ae1"
          // marginTop:-100

        }}>
          <GridContainer justify="center"
           style={{marginTop:width<500?-60:-45}}
           >
            <GridItem xs={12} sm={12} md={4} >
              <Card className={classes[cardAnimaton]} 
              style={{borderColor:"#818485",borderStyle:"solid",borderWidth:"3px"}}
              >
                <form className={classes.form} >
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:"white",borderColor:"#818485",borderStyle:"solid",borderWidth:"3px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
     {
      width<500?
      <p style={{color:'#268ae1',fontWeight:"bold",fontFamily:"fantasy",fontSize:25,marginRight:"2%"}}>L<span style={{color:"#818485"}}>o</span>g<span style={{color:"#818485"}}>i</span>n</p>
:<></>
     }
                    
      <img src={width<500?image2: image1} style={{height:isMobile? 100:100,width:isMobile?100:240}} >
      
      </img>

                    
                  </CardHeader>
                  <CardBody>
                 
                  <FormControlLabel
                //  style={{marginLeft:"1em"}}
                style={{width:"30%"}}

                 control={<Checkbox color="success" style={{color:"#268ae1"}} checked={role==="client"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  setRole("client")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Client</Typography>}
               />
                <FormControlLabel
                //  style={{marginLeft:"1em"}}
                style={{width:"30%"}}

                 control={<Checkbox color="success" style={{color:"#268ae1"}} checked={role==="user"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  // setloginType(e.target.value)
                  setRole("user")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>User</Typography>}
               />
               <br></br>
               <FormControlLabel
                  style={{width:"30%"}}
                //  style={{marginLeft:"1em"}}
                 control={<Checkbox color="success" style={{color:"#268ae1"}} checked={loginType==="Phone"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  setloginType("Phone")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Phone</Typography>}
               />
              <FormControlLabel
                 style={{width:"30%"}}
                 control={<Checkbox color="success"  style={{color:"#268ae1"}} checked={loginType==="email"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  // setloginType(e.target.value)
                  setloginType("email")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Email</Typography>}
               />
               
                   <FormControlLabel
                   style={{width:"30%"}}
                //  style={{marginLeft:"1em"}}
                 control={<Checkbox color="success" style={{color:"#268ae1"}}  checked={loginType==="name"} onChange={(e)=>{
                  //  this.setState({ checkedCAG: e.target.checked})
                  setloginType("name")
                 }} />
                }
                 label= {<Typography style={{ fontWeight: 'bold',color:"#767879" }}>Name</Typography>}
               />
              
                
                  <TextField required id="" block
                  fontColor="red"
                  // style={{borderBottom:"2px solid red"}}
                  inputProps={{ autoComplete: 'off' }}
                  fullWidth={true}
                  label="UserName / ID"
                  value={email}
                                  InputLabelProps={{ style: { color: '#767879' ,fontWeight:"bold"} }}

                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }} 
                  // InputProps={{
                  //   classes: {
                  //     underline: "red",
                  //   },
                  // }}
                  // InputLabelProps={{
                  //   style: { color: 'red' },
                  // }}
                  />
                  
                  <br />
                  <br />
                    <TextField required 
                    id="password" block
                  fullWidth={true}
                  label="Password"
                  value={password}
                  type={values.showConfrimPass ? 'text' : 'password'}
                  InputLabelProps={{ style: { color: '#767879' ,fontWeight:"bold"} }}
                  inputProps={{ autoComplete: 'off' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfrimPass}
                        onMouseDown={handleMouseDownConfrimPass}
                      >
                        {values.showConfrimPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e)=>{
                    setPassword(e.target.value)
                  }} 
                  
                  />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button className="loginButton" color="#196c90" size="lg" style={{backgroundColor:'#268ae1'}}
                     onClick={() => {
                      // localStorage.setItem('user_id',"00001")
                      // localStorage.setItem('user_name',"aamir");
                  // history.push('/Home'); 
                       if(email.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Please Enter Login Name !!')
                        return
                    
                       }
                       if(password.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Please Enter Password !!')
                        return
                    
                       }
                       console.log("check login value ")
                       console.log(email)
                       console.log(password)
                       console.log(role)
                       console.log(loginType)

                      //  var log='ID'
                      // example of JTell https://4dserver.jtelwholesale.com/4DACTION/
                      // fetch('https://74.208.115.190/4DACTION/WebMobAppS_UserLogin',{
                      fetch('https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_UserLogin',{


                    
                      method: 'POST',
                        'Content-Type':"application/json",
                        body: JSON.stringify({
                          login_role:role,
                          login_type:loginType,
                          login_id:email,
                          login_password:password
                        })
                      }).then((res)=>res.json())
                      .then( async(response)=>{
                        console.log(JSON.stringify(response))
                                  

                        var con=false

                        if(response.if_found){
                          
                          localStorage.setItem('user_id',response.my_id)
                         
                          localStorage.setItem('if_admin',response.if_admin)
                          localStorage.setItem('user_type',response.my_type)
                          localStorage.setItem('user_name',response.my_name)
                       

                          


                         

// dispatcchUserEvents("LoginCred",{token:response[0].valid_token,branchid:response[0].branch_id,userId:response[0].user_id,userName:response[0].user_name,adminRight:response[0].admin_rights})
                      history.push('/Home');              

                          
}
else{
  // alert("Please Enter Valid Credentials")
  setClassicModal(true)
  setServerResponse('Please Enter Valid Credentials !!')
}

                      })
                                  
                      }}
                    >
                      Login
                    </Button>
                    
                  </CardFooter>
                </form>
               
               
              </Card>
            </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Login</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                   {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>
         
        </div>
      </div> */}
       <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h3 className={classes.modalTitle}><b>Login</b></h3>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p style={{fontWeight:"bold"}}>
                   {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
    </div>
  );
}
