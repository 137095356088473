/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { NavLink,useHistory } from "react-router-dom";
// import { Link ,useHistory} from "react-router-dom";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import useWindowDimensions from "../../useWindowDimensions";

// @material-ui/icons
import { Apps, CloudDownload, ImportantDevices } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import Button from "components/CustomButtons/Button.js";
import { Button } from "@material-ui/core";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
// import url from '../../link'
import "./Header.css"


// import MplProfile from "../../assets/pdf/MplProfileNew.pdf"
// import AutoimmuneDiagnostics from "../../assets/pdf/AutoimmuneFlyer.pdf"
// import Echoflyer from "../../assets/pdf/Echoflyer.pdf"
// import Fibroscan from "../../assets/pdf/FibroscanFlyer.pdf"
// import OPG from "../../assets/pdf/OPGFlyer.pdf"


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();
  const { width } = useWindowDimensions();


  

  return (
    <List className={classes.list}>
      
      {/* Home for all Header that are honePage in index.js*/}
      {/* <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
        class="button-71" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/Home');              

        }}
        >
         
          <b>Home</b>
        </Button>
      </ListItem> */}
      {/* <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#cb1a2c', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
          
        >
          <NavLink to="/" 
          style={{color:'white'}}
          >Home</NavLink>
        </Button>
      </ListItem> */}
      
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        {/* <Button
          style={{
            // backgroundColor:'white',
          paddingRight:"12px",
          paddingLeft:"12px",
          borderColor:"#cb1a2c",
            borderWidth:"2px",
            borderStyle:"solid"
          
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
          
        >
          
          <NavLink to="/Invoice" 
          style={{color:'black'}}
          ><b>Invoice New</b></NavLink>
        </Button> */}
         <Button
        class="button-71" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/Invoice');              

        }}
        >
          {/* <NavLink to="/" 
          style={{color:'white'}}
          ><b>Home</b> </NavLink> */}
          <b>New Order</b>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        {/* <Button
          style={{
            // backgroundColor:'#cb1a2c', 
            // backgroundColor:'white',
            paddingRight:"12px",
            paddingLeft:"12px",
            borderColor:"#cb1a2c",
            borderWidth:"2px",
            borderStyle:"solid"
          // boxShadow: "10px 10px 5px #cccc",
          // boxShadow:" 5px 5px #cccc"
        }}
          
        >
          
          <NavLink to="/InvoiceUpdate" 
          style={{color:'black'}}
          ><b>Invoice Edit</b></NavLink>
        </Button> */}
           <Button
        class="button-71" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"
            marginTop:width<950?"2%":0


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/InvoiceUpdate');  
          // alert("Sorry! This page is under process....")            

        }}
        >
          {/* <NavLink to="/" 
          style={{color:'white'}}
          ><b>Home</b> </NavLink> */}
          <b>Modify Order</b>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
       
           <Button
        class="button-71" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"
            marginTop:width<950?"2%":0


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/InvoicePdf');
          // alert("Sorry! This page is under process....")    
          // alert("Coming soon.....")      
          // window.open('http://70.35.203.150:8090/4DACTION/WebInvoiceDownloadingPDF_api?invoice_id=03-INV-01475&user_id=04')        
  //         fetch("http://70.35.203.150:8090/4DACTION/WebInvoiceDownloadingPDF_api",
  //         {
  //           method:"POST",
  //           'Content-Type':"application/json",
  //           body: JSON.stringify({
  //            invoice_id:"03-INV-01475",
  // user_id:localStorage.getItem('user_id'),
         
  //           })
  //         }).then((res) => res.json()).then((response) => {
  //           console.log("check pdf "+response)
  //         })
        }}
        >
          {/* <NavLink to="/" 
          style={{color:'white'}}
          ><b>Home</b> </NavLink> */}
          <b>Pdf Download</b>
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="Invoice"
          style={{backgroundColor:'#cb1a2c !important'}}
          className={classes.navLink}
          dropdownList={[
  //           <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
  //  Who We Are
  //           </NavLink>,
  <NavLink to="/Invoice" 
  className={classes.dropdownLink}
  >Invoice New </NavLink>,
  <NavLink to="/InvoiceUpdate" className={classes.dropdownLink}>
 Invoice Update
               </NavLink>,
                   
  
              

          ]}
        />
      </ListItem> */}
      {(localStorage.getItem('user_id')) ? 
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        {/* <Button
          style={{
            // backgroundColor:'white',
            color:"black" ,
            paddingRight:"25px",
          paddingLeft:"25px",
         
            borderWidth:"2px",
            borderStyle:"solid"
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          let text;
          if (confirm("Are You sure To Logout ?") == true) {
            console.log("ok hua check ")
            localStorage. removeItem('user_id')
            localStorage. removeItem('user_name')
            location.reload();
        
          } else {
            console.log("check "+text)
            console.log("cancel hua hua check ")

           
          }
        }}
        >
        <b>Logout</b>
        </Button> */}
              <Button
        class="button-71" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"
            marginTop:width<950?"2%":0,



          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
    padding: "16px 35px"

        }}
        onClick={() => {
          let text;
          if (confirm("Are You sure To Logout ?") == true) {
            console.log("ok hua check ")
            // localStorage.removeItem('user_id')
            // localStorage.removeItem('user_name')
            // history.push("/")

            localStorage.clear();
            history.push("/Login")
            // location.reload();
        
          } else {
            console.log("check "+text)
            console.log("cancel hua hua check ")

           
          }
        }}
        >
          {/* <NavLink to="/" 
          style={{color:'white'}}
          ><b>Home</b> </NavLink> */}
          <b>Logout</b>
        </Button>
      </ListItem>
      :<></>}
       
    
      {(localStorage.getItem('user_id')) ? 
      //  <ListItem className={classes.listItem} style={{textAlign:'center'}}>
      <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center',marginTop:"1%"}}>
        <img src={require('assets/img/welcome.png')}
       style={{width:'2em',}}
       />
         <b style={{color:'black'}}> Welcome<br/>
      
      {localStorage.getItem('user_name')}</b>

       {/* <Button
          style={{
            backgroundColor:'white', 
          padding:0,
          borderColor:"white",
            borderWidth:"2px",
            borderStyle:"solid",
            boxShadow:"0"
      }}
        
       >
       <img src={require('assets/img/welcome.png')}
       style={{width:'2.5em',}}
       />
      <b style={{color:'black'}}> Welcome
      
        <br />{localStorage.getItem('user_name')}</b>

       </Button> */}
        {/* <img src={require('assets/img/welcome.png')}
       style={{width:'2em',}}
       />
      <b style={{color:'black'}}>
      
        {localStorage.getItem('user_name')}</b> */}
     </ListItem>
      :<></> }
     
      {/* <ListItem className={classes.listItem} style={{marginRight:'15px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#cb1a2c', 
          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
          
        >
          <NavLink to="/" 
          style={{color:'white'}}
          >Home </NavLink>
        </Button>
      </ListItem> */}
      
      
        {/* Home for all Header that are honePage in index.js*/}
       

{/* 2-sep-2023 About us Patient ko b nazar aae */}
      {/* {
        localStorage.getItem('role')!='Patient' ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
  //           <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
  //  Who We Are
  //           </NavLink>,
  <NavLink to="/CeoMessage" 
  className={classes.dropdownLink}
  >CEO's Message </NavLink>,
  <NavLink to="/OurMission" className={classes.dropdownLink}>
  Mission & Vision
               </NavLink>,
                   
  <NavLink to="/OurTeam" 
  className={classes.dropdownLink}
  >Team </NavLink>,
  <NavLink to="/NewsAndFeed" 
  className={classes.dropdownLink}
  >News & Feed </NavLink>,
  <NavLink to="/OurGallery" 
  className={classes.dropdownLink}
  >Gallery </NavLink>,
       
            
                  // <NavLink to="/CompanyProfile" className={classes.dropdownLink}>
                  // Company Profile
                  //              </NavLink>,
            // <a  className={classes.dropdownLink} href={MplProfile} download="MPL-Profile.pdf">Download Mpl Profile</a>
           
              

          ]}
        />
      </ListItem>
      :null
      } */}
      {/* <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
  //           <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
  //  Who We Are
  //           </NavLink>,
  <NavLink to="/CeoMessage" 
  className={classes.dropdownLink}
  >CEO's Message </NavLink>,
  <NavLink to="/OurMission" className={classes.dropdownLink}>
  Mission & Vision
               </NavLink>,
                   
  <NavLink to="/OurTeam" 
  className={classes.dropdownLink}
  >Our Team </NavLink>,
  <NavLink to="/NewsAndFeed" 
  className={classes.dropdownLink}
  >News & Feed </NavLink>,
  <NavLink to="/OurGallery" 
  className={classes.dropdownLink}
  >Gallery </NavLink>,
       
            
                  // <NavLink to="/CompanyProfile" className={classes.dropdownLink}>
                  // Company Profile
                  //              </NavLink>,
            // <a  className={classes.dropdownLink} href={MplProfile} download="MPL-Profile.pdf">Download Mpl Profile</a>
           
              

          ]}
        />
      </ListItem> */}
    
      {/* NewsAndFeeds 31-dec-2022 */}
      {/* <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90',
          // boxShadow:" 5px 5px #cbcccd"
          // boxShadow:" 5px 5px #faa931"

        }}
          
        >
          <NavLink to="/NewsAndFeed" 
          style={{color:'white'}}
          >News & Feed </NavLink>
        </Button>
      </ListItem> */}

      {/* Printing 08-march-2023 */}
      {/* <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90'}}
          
        >
          <NavLink to="/Printing" 
          style={{color:'white'}}
          >Print </NavLink>
        </Button>
      </ListItem> */}
     {/* Service part in Header that are drop down when we click its move to that screen define in index.js "/Clinic_Chemistry&Immunology" etc */}
     
      {/* <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center',}}>
        <CustomDropdown
          buttonText="SERVICES"
          style={{backgroundColor:'#196c90',
        }}
          className={classes.navLink}
      
          dropdownList={[
            <NavLink to="/Clinic_Chemistry&Immunology" className={classes.dropdownLink}>
   Clinical Chemistry & Immunology
            </NavLink>,
            <NavLink to="/Hematology" className={classes.dropdownLink}>
            Hematology
                         </NavLink>,
            
              
              <NavLink to="/Microbiology&ClinicalPathology" className={classes.dropdownLink}>
                 Microbiology & Clinical Pathology

                </NavLink>,
                  <NavLink to="/Molecular" className={classes.dropdownLink}>
                  Molecular Pathology & Genetics
                    </NavLink>,
              <NavLink to="/Histopathology" className={classes.dropdownLink}>
                     
Histopathology

                        </NavLink>,
            
                        <NavLink to="/Special_Pathology" className={classes.dropdownLink}>
                        Special Pathology
                          </NavLink>,
                           <NavLink to="/Ultrasound" className={classes.dropdownLink}>
                           Ultrasound
                                        </NavLink>,
                                         <NavLink to="/Echocardiography" className={classes.dropdownLink}>
                                        Echocardiography
                                                      </NavLink>,
                                        <NavLink to="/Fibroscan" className={classes.dropdownLink}>
                                        Fibroscan
                                        </NavLink>,
                                        <NavLink to="/DigitalXray" className={classes.dropdownLink}>
                                        Digital X-Ray
                                        </NavLink>,
                                        <NavLink to="/OPG" className={classes.dropdownLink}>
                                        OPG (Orthopantomogram)
                                        </NavLink>,
                                        <NavLink to="/Spirometry" className={classes.dropdownLink}>
                                        Spirometry 
                                        </NavLink>,
                        //  <NavLink to="/Covid" className={classes.dropdownLink}>
                        // Covid 19 Molecular Lab
                        // </NavLink>,




          ]}
        />
      </ListItem> */}
   
      {/* if login person are not patient then show */}
      {/* {
        localStorage.getItem('role')!='Patient' ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
            <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
   Who We Are
            </NavLink>,
             <NavLink to="/OurMission" className={classes.dropdownLink}>
Mission & Vision
             </NavLink>
              

          ]}
        />
      </ListItem>
      :null
      } */}
       {/* if login person are  patient then show patient Id and passworn on diary */}
       {/* 2-sep-2023 ko header se remove kiya */}
      {/* {
        localStorage.getItem('role')=='Patient' ?
        <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
      >
        
        <NavLink to="/COVID19_HomeSampling" 

        style={{color:'white'}}
      

      >Book COVID-19 Home Sampling</NavLink>
      </Button>
    </ListItem>
      : null
      } */}
      {/* onLine Reports me login ni hona chahaye when click on this go to Login page */}
      {/* {(localStorage.getItem('patient')==null && localStorage.getItem('emirates')==null && localStorage.getItem('qatar')==null && localStorage.getItem('flydubai')==null && localStorage.getItem('onix')==null) && localStorage.getItem('airblue')==null && localStorage.getItem('etihad')==null && localStorage.getItem('OmanAir')==null && localStorage.getItem('SereneAir')==null ? 
      
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
      >
        <NavLink to="/Login" 

        style={{color:'white',
        
      }}
      

        >Online Reports</NavLink>
      </Button>
    </ListItem>
    : <></>
    } */}
    {/* if localStorage have emirates then only show 3 main menu item */}
       
       
     
  
  
   
      
      
      {/* <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="MENU"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test
          </NavLink>,
          <NavLink to="/Quotation" className={classes.dropdownLink}>
Get a Quote
</NavLink>,

        ]}
        // get a qoute k nichy se cut kiye hn 
//         <NavLink to="/QatarLogin" className={classes.dropdownLink}>
// Qatar Airways
// </NavLink>,
// <NavLink to="/Emirates" className={classes.dropdownLink}>
// Emirates
// </NavLink>,
// <NavLink to="/FlyDubai" className={classes.dropdownLink}>
// FlyDubai
// </NavLink>,
// <NavLink to="/AirBlue" className={classes.dropdownLink}>
// Air Blue
// </NavLink>,
// <NavLink to="/SereneAir" className={classes.dropdownLink}>
// Serene Air
// </NavLink>,
// <NavLink to="/OmanAir" className={classes.dropdownLink}>
// Oman Air
// </NavLink>,
// <NavLink to="/Onix_Enterprises" className={classes.dropdownLink}>
// Onix Enterprises
// </NavLink>
      />
    </ListItem>
       */}
      
       
        {/* NewsAndFeeds 31-dec-2022 */}
       
       
      
      
    </List>
  );
}
