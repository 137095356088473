import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Card from "components/Card/Card.js";

// import image1 from "assets/img_stock/dashboard.JPG";
// import image1 from "assets/img_stock/New2023/Hp1New.jpg";
// import image1 from "assets/img_stock/New2023/backgroundFinal4.jpg";


// import image3 from "assets/img_stock/New2023/Hp3.jpg";
// import image4 from "assets/img_stock/New2023/Hp4.jpg";






// import image3 from "assets/img_stock/Molecular_Genetics.JPG";


// import image6 from "assets/img_stock/Micro_1.JPG";

// import image4 from "assets/img/MPL_BANNER.jpeg";

// import image5 from "assets/img_stock/bio_chem_2.JPG";
import image1 from "../../../images/MM/Untitled design (12).png";
import image2 from "../../../images/MM/Untitled design (15).png";
// import image3 from "assets/img_stock/FutureScientific/Carousal/slider3.jpeg";
import image3 from "../../../images/MM/Untitled design (17).png";
import image4 from "../../../images/MM/Untitled design (17)new.png";


//6-jul-2023
import useWindowDimensions from "../../../useWindowDimensions";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import { Height } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },]
  };
  return (
    // 21-june-2023
      // <div className={classes.container} style={{width:'100%',backgroundColor:"green",height:"100%"}}>
            // <Card carousel style={{ margin:"auto",}} >
              <Carousel  {...settings} speed={5000} autoplaySpeed={5} >
              {/* <div>
                  <img src={image4} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                 
                  <NavLink to="/HomeSampling" className={classes.dropdownLink}>
                    <h1 style={{color:"black",marginTop:"-10%"}}>See More ...</h1>
                   </NavLink>
                  </div>
                </div> */}
              
                {/* <div>
                  <img src={image1} alt="Third slide" className="slick-image" /> */}
                  {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                 
                  <NavLink to="/HomeSampling">
                    <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                   </NavLink>
                  </div> */}
                {/* </div> */}
                {width<500?
            
               <div>
               <img src={image1} alt="Third slide" className="slick-image" />
               {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
              
               <NavLink to="/HomeSampling">
                 <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                </NavLink>
               </div> */}
             </div>
            
         :
              <div>
              <img src={image2} alt="Third slide" className="slick-image" />
              {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
             
              <NavLink to="/HomeSampling">
                <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
               </NavLink>
              </div> */}
            </div>  
              }
               
               {width<500 &&
              
              
             <div>
               <img src={image3} alt="Third slide" className="slick-image" />
               {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
              
               <NavLink to="/HomeSampling">
                 <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                </NavLink>
               </div> */}
             </div>
           
          
              }
                {width<500 &&
              
              
              <div>
                <img src={image4} alt="Third slide" className="slick-image" />
                {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
               
                <NavLink to="/HomeSampling">
                  <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                 </NavLink>
                </div> */}
              </div>
            
           
               }
                {/* <div>
                  <img src={image2} alt="Third slide" className="slick-image" /> */}
                  {/* <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                 
                  <NavLink to="/HomeSampling">
                    <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                   </NavLink>
                  </div> */}
                {/* </div> */}
                {/* <div>
                  <img src={image2} alt="Third slide" className="slick-image" />
                 
                </div> */}
                {/* <div>
                  <img src={image3} alt="Third slide" className="slick-image" />
                  <div className="slick-caption" style={{borderRadius:"5px 25px",borderColor:"#196c90",borderWidth:"2.5px",borderStyle:"solid", marginBottom:"10%",width:"25%",marginLeft:"23%",height:"7.5%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                 
                  <NavLink to="/HomeSampling">
                    <p style={{color:"#196c90",fontSize:"200%",fontWeight:"normal"}}>See More ...</p>
                   </NavLink>
                  </div>
                </div> */}
                {/* <div>
                  <img src={image2} alt="Third slide" className="slick-image" />
                 
                </div> */}
                {/* <div>
                  <img src={image3} alt="Third slide" className="slick-image" />
                  <div className="slick-caption">
                  <NavLink to="/HomeSampling" className={classes.dropdownLink}>
                    <h1 style={{color:"black",marginTop:"-10%"}}>See More ...</h1>
                   </NavLink>
                  </div>
                </div> */}
                {/* <div>
                  <img src={image4} alt="Third slide" className="slick-image" />
                  <div className="slick-caption">
                  <NavLink to="/HomeSampling" className={classes.dropdownLink}>
                    <h1 style={{color:"black",marginTop:"-10%"}}>See More ...</h1>
                   </NavLink>
                  </div>
                </div> */}
              </Carousel>
            // </Card>
         
      // </div>
    
  );
}
