import React, { useEffect, Fragment,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
//24-march-2023 for acs desc
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import useWindowDimensions from "../../useWindowDimensions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from "@material-ui/core/Checkbox";
import mobile from "../../images/MM/mobile.png"

import MenuItem from '@material-ui/core/MenuItem';


// import Grid from "@material-ui/core/Grid";


import HelpIcon from '@material-ui/icons/Help';
import DescriptionIcon from '@material-ui/icons/Description';
// import image from "../../images/A1 Wholesale/01 Heading.png"
import image from "../../images/A1 Wholesale/HeadingNew.jpeg"


import { isMobile } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import { NavLink } from "react-router-dom";
import { Link,useHistory } from "react-router-dom";



import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';
// import Invoice from './PDF/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';
import { Html5QrcodeScanner } from 'html5-qrcode';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from '@date-io/date-fns';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
// import CovidTemplate from './Covid_PDF/CovidTemplate'
// import TemplateCertificate from './TemplateCertificate/TemplateCertificate'
import GroupIcon from '@material-ui/icons/Group';
// import CertificateTemplate from './Certificate/CertificateTemplate'

// import SignCovidTemplate from './Sign_Covid_PDF/SignCovidTemplate'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import Covid_AntiGen from './Covid_AntiGen/CovidTemplate'
import { bool } from 'prop-types';
import { ProductContex } from '../../ContextStore.js';
import CropFreeIcon from '@material-ui/icons/CropFree';


import TextField from '@material-ui/core/TextField'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id = localStorage.getItem('patient_name') + ""





const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

export default function Lab_Tests({}) {
  const {dispatcchUserEvents,billAmount,cartItems,TotalAmount,TotalDiscount,TotalPayable,TotalPayableAddDisc,TotalRfdAmount,TotalRfdPayable,TotalRfdPayableAddDisc,TotalRefundDiscountAmount} = useContext(ProductContex);
  var from = new Date();
  var fromNew = new Date();


  const classes = useStyles();
  const { width } = useWindowDimensions();

  const [CategoriesList, setCategoriesList] = React.useState([])
  const [DoctorNameList, setDoctorNameList] = React.useState([])
  const [ProductList, setProductList] = React.useState([])
  const [SelectedProducts, setSelectedProducts] = React.useState([])
  const [remarks,setremarks]=React.useState("")
  const [specialDiscountPer,setSpecialDiscountPer]=React.useState(0)
  const [specialDiscountValue,setSpecialDiscountValue]=React.useState(0)
  // for refund special discount
  const [rfdSpecialDiscountPer,setrfdSpecialDiscountPer]=React.useState(0)
  const [rfdSpecialDiscountValue,setrfdSpecialDiscountValue]=React.useState(0)
  //for count data 
  const [count,setcount]=React.useState(1)
  // dialoge box
  const [DialogBoxOpen,setDialogBoxOpen]=React.useState(false)
  //client list 
  const [ClientList,setClientList]=React.useState([])
  // this is for client selection
  const [open,setOpen]=React.useState(false)
   // this is for Previous Invoice Selection
   const [openPreInvoice,setopenPreInvoice]=React.useState(false)
  const [fromdate, setFromDate] = React.useState(from)
  const [todate, setToDate] = React.useState(from)
  const [ifPeriodApplyYes, setifPeriodApplyYes]= React.useState(true)
  const [ifPeriodApplyNo, setifPeriodApplyNo]= React.useState(false)
  const [PreInvoiceData, setPreInvoiceData]= React.useState([])
  // for other variable
  const [invoiceId,setInvoiceId]=React.useState("")
  const [branchId,setbranchId]=React.useState("")
  const [invoiceDate,setinvoiceDate]=React.useState("")

  const [clientId,setclientId]=React.useState("")
  const [clientTitle,setclientTitle]=React.useState("")

  const [noRecordFound,setnoRecordFound]=React.useState(false)
  // for product list autocomplete jab tak prev invoice select na ho ye na chaly
  const [ProductListempty, setProductListempty] = React.useState([{
    item_name:"Please Select Previous Invoice ! "
  }])
  const [loading,setLoading]=React.useState(false)

  const history = useHistory();
    //6-june-2024 for client alert for admin pls select client first
    const [clientSelect,setClientSelect]=React.useState(false)
 // for qr code 
 const [scanResult,setscanResult]=React.useState(null)
 const [openQrCodeModal,setopenQrCodeModal]=React.useState(false)
// 21-june-2024 
const [showQrScanner,setshowQrScanner]=React.useState(false)

    useEffect(()=>{
      //for qr code
   const scanner =new Html5QrcodeScanner ('reader',{
    qrbox:{
      width:350,
      height:350
    },
    fps:5,
  })
  scanner.render(success,error)
  
  function success(result){
    scanner.clear();
    setscanResult(result)
    setopenQrCodeModal(true)
  
  }
  function error(err){
    // scanner.clear();
    // console.log("error in scanner 531"+err)
  
  }
      
        },[openQrCodeModal])
    const rerenderQrScanner=()=>{
      // const scanner =new Html5QrcodeScanner ('reader',{
      //   qrbox:{
      //     width:350,
      //     height:350
      //   },
      //   fps:5,
      // })
      // scanner.render(success,error)
      
      // function success(result){
      //   scanner.clear();
      //   setscanResult(result)
      //   setopenQrCodeModal(true)
      
      // }
      // function error(err){
      //   // scanner.clear();
      //   console.log("error in scanner 531"+err)
      
      // }
      setLoading(true)
      // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingContent",
      var obj={
        invoice_id:scanResult,
        user_id:localStorage.getItem('user_id'),
      }
      console.log(obj)
      fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_InvoiceLoadsContent",

      {
        method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify({
         invoice_id:scanResult,
user_id:localStorage.getItem('user_id'),
// 14-aug-2024 for new api condition jo 14-aug ki sham ko milli clientid b deni hai qr code scanner me 

 client_id:localStorage.getItem('client_id')


       
        })
      }).then((res) => res.json()).then((response) => {
      
      console.log("check InvoiceModifyLoadingContent response "+JSON.stringify(response))
    

//                       console.log("check InvoiceModifyLoadingContent response "+JSON.stringify(response[0].ArrEntrySequence))


//           //  start #############
var ArrayData=[]
JSON.parse(response[0].ArrItemSystemID).map((value,index)=>{

console.log("check map  "+JSON.stringify(value))
            const obj ={
sn:index+1,
systemId: JSON.parse(response[0].ArrItemSystemID)[index],
code: JSON.parse(response[0].ArrItemCode)[index],
itemName:JSON.parse( response[0].ArrItemName)[index],
rate:JSON.parse(response[0].ArrItemRate)[index],
qty:JSON.parse(response[0].ArrItemQty)[index],
rfd:0,
rfdamount:0,


amount:JSON.parse(response[0].ArrItemRate)[index],
discount:JSON.parse(response[0].ArrItemDisc_perc)[index],
discountValue:JSON.parse(response[0].ArrItemDisc_value)[index],
netvalue:JSON.parse(response[0].ArrItemAmount)[index],
requestedQuantity:1

// buyingPrice:JSON.parse(value.ArrItemUnitCost)[index],
}

console.log("check map inner  "+JSON.stringify(obj))


//               JSON.parse(value.ArrEntrySequence).map((chk,index)=>{
//               console.log("check map INNER  "+chk+"INDEX"+index)
//               // console.log("check map QTY  "+value.ArrItemQty[index]+"INDEX"+index)

//               const obj ={
//                 sn:JSON.parse(value.ArrSequenceNo)[index],
//                 systemId: JSON.parse(value.ArrItemSystemID)[index],
//                 code: JSON.parse(value.ArrItemCode)[index],
//                 itemName:JSON.parse( value.ArrItemName)[index],
//                 rate:JSON.parse(value.ArrItemRate)[index],
//                 qty:JSON.parse(value.ArrItemQty)[index],
//                 rfd:JSON.parse(value.ArrRefundQty)[index],
//                 rfdAmount:JSON.parse(value.ArrRefundValue)[index], 
//                 amount:JSON.parse(value.ArrItemAmount)[index],
//                 disc:JSON.parse(value.ArrDiscountPercent)[index],
//                 discount:JSON.parse(value.ArrDiscountValue)[index],
//                 netvalue:JSON.parse(value.ArrItemAmount)[index],

//                 buyingPrice:JSON.parse(value.ArrItemUnitCost)[index],
//               }

ArrayData.push(obj)

//               })
// // for special discount 
// setSpecialDiscountPer(value.inv_discount_special_percent)
// setSpecialDiscountValue(value.inv_discount_value)
// setrfdSpecialDiscountPer(value.refund_special_discunt_percent)
// setrfdSpecialDiscountValue(value.refund_special_discount_value)
// // for other variable
// setInvoiceId(value.invoice_id)
// setbranchId(value.branch_id)
// setinvoiceDate(value.invoice_date)
// setclientId(value.client_id)
// setclientTitle(value.client_title)


})
console.log("check Cart Array"+JSON.stringify(ArrayData))
//             setcount(ArrayData[0].sn+1)

dispatcchUserEvents("UpdateInvoice",{updateArray:ArrayData})


setopenPreInvoice(false)
console.log("check invoice id ")
console.log(response[0].invoice_id)
dispatcchUserEvents("InvoiceId",{invoiceId:response[0].invoice_id})
dispatcchUserEvents("scanqrcode",{scanqrcode:"yes"})
// 14-aug-2024 for new api condition jo 14-aug ki sham ko milli clientid b deni hai qr code scanner me 
if(localStorage.getItem("client_id")){

}
else{
localStorage.setItem('client_id',response[0].client_id)
localStorage.setItem('client_Title',response[0].client_title)
}


      setLoading(false)
      history.push("/Invoice")
     })
     .catch((e)=>{
console.log(e)
alert("sorry data not fetch from backend !"+e)
setLoading(false)

     })
    }
    
  useEffect(()=>{
console.log("yess")
if(localStorage.getItem('user_id')  ){
  // window.location.href="https://mpl-labs.pk"
  
 }
 else{
  history.push('/Login');  
 }
  },[cartItems])

  const [columns, setColumns] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Rate $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    {
      title: "Rfd", field: 'rfd', type: 'numeric' ,
      //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
      }
    },
    {
      title: 'Amount $', field: 'amount', type: 'numeric', editable: 'never', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
      }
    },
    {
      title: 'Disc %', field: 'disc', type: 'numeric', 
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
      }
    },
    {
      title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
      }
    },
    {
      title: 'Net Val $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    }
  ]);
  // cond lgae hai ager login pe if discount true hai to disc column edit ho sky
  const [columnsNewForCondiotion, setcolumnsNewForCondiotion] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Rate $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    {
      title: "Rfd", field: 'rfd', type: 'numeric' ,
      //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
      }
    },
    {
      title: 'Amount $', field: 'amount', type: 'numeric', editable: 'never', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
      }
    },
    {
      title: 'Disc %', field: 'disc', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
      }
    },
    {
      title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
      }
    },
    {
      title: 'Net Val $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    }
  ]);
  const [data, setdata] = React.useState([
    {
    sn:1,
    code:"ABC",
    itemName:"abc",
    rate:20,
    qty:10,
    rfd:10,
    amount:100,
    discount:100,
    netvalue:100
  }])

//   useEffect(()=>{
// dispatcchUserEvents("ClearData")

//     // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsCategoriesCallingUp",
//     // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsCategoriesCallingUp",

//     // {
//     //   method:"POST",
//     //   'Content-Type':"application/json",
//     //   body: JSON.stringify({
//     //     // branch_id:1,
//     //     branch_id:parseInt(localStorage.getItem('branch_id')),

//     //     user_id:localStorage.getItem('user_id')
//     //   })
//     // }).then((res) => res.json()).then((response) => {
     
//     // setCategoriesList(response)
  

//     // })
//     // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientsGenListCallingUp_api",
//     fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientsGenListCallingUp_api",

//     {
//       method:"POST",
//       'Content-Type':"application/json",
//       body: JSON.stringify({
//         // branch_id:1,
//         branch_id:parseInt(localStorage.getItem('branch_id')),

//         user_id:localStorage.getItem('user_id')

//       })
//     }).then((res) => res.json()).then((response) => {
//     // console.log("check Clients data "+JSON.stringify(response))  
//     // setClientList(response)
//     var data = [];
//     response.map((item) => {
//       // console.log(item)
     
//       var obj = {
//         clientTitle: item.arr_cl_name,
//         phone: item.arr_phone,
//         city: item.arr_city,
//         state: item.arr_state,
//         zip: item.arr_zip,
//         address: item.arr_address,

//         select: (
//           <Button
//             variant="contained"
//             color="primary"
//             style={{
//             backgroundColor:"#cb1a2d"

//             }}
//             className={classes.button}
//             onClick={() => {
//               setclientId(item.arr_cl_id)
//               setclientTitle(item.arr_cl_name)
//               // localStorage.setItem('client_id',item.arr_cl_id)
//               // localStorage.setItem('client_Title',item.arr_cl_name)
//           // setId(item.Patient_ID)
//           // setName(item.Patient_Name)
//           // setMobile(item.MobileNo)
//           setOpen(false)
//             }}
//           >
//             Select
//           </Button>
//         ),
//       };

//       data.push(obj);
//     });
//     setClientList(data);
//     // setOpen(true);
//     // setCategoriesList(response)
//     //   var obj = {}
//     //   response.map((item) => {
//     //     obj[item.DoctorID] = item.DoctorName
//     //   })
//       // setDoctorNameList(obj)

//     })
//     // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",
//     fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

//     {
//       method:"POST",
//       'Content-Type':"application/json",
//       body: JSON.stringify({
//         // branch_id:1,
//         branch_id:parseInt(localStorage.getItem('branch_id')),

//         category_id:0,
//         user_id:localStorage.getItem('user_id')

//       })
//     }).then((res) => res.json()).then((response) => {
//     // console.log("check Products response "+JSON.stringify(response))  
//     setProductList(response)
//     //   var obj = {}
//     //   response.map((item) => {
//     //     obj[item.DoctorID] = item.DoctorName
//     //   })
//       // setDoctorNameList(obj)

//     }).catch((err)=>{
//       console.log("err "+err)

//      alert("Session Expired "+err)
//      localStorage.clear()
// //       if(err.includes("Invalid User ID")){
// // localStorage.clear()

// //       }
//     })
//     var fromNew =new Date()
//     var to = new Date();
//     to.setDate(to.getDate() - 2);


   
//   },[])
useEffect(()=>{
  dispatcchUserEvents("ClearData")
  
      
      
      // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsCategoriesCallingUp",
  
      // {
      //   method:"POST",
      //   'Content-Type':"application/json",
      //   body: JSON.stringify({
      //     // branch_id:1,
      //     branch_id:parseInt(localStorage.getItem('branch_id')),
  
        
      //     user_id:localStorage.getItem('user_id')
      //   })
      // }).then((res) => res.json()).then((response) => {
    
      // setCategoriesList(response)
     
  
      // })
      console.log(localStorage.getItem('user_id'))
  if(localStorage.getItem('if_admin')=="true"){
     
      fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_ClientsListCallingUp",
  
      {
        method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify({
          // branch_id:1,
          // branch_id:parseInt(localStorage.getItem('branch_id')),
        
          user_id:localStorage.getItem('user_id')
        })
      }).then((res) => res.json()).then((response) => {
  
      var data = [];
      response.map((item) => {
        console.log(item)
       
        var obj = {
          clientTitle: item.arr_cl_name,
          phone: item.arr_phone,
          city: item.arr_city,
          state: item.arr_state,
          zip: item.arr_zip,
          address: item.arr_address,
  
          select: (
            <Button
              variant="contained"
              color="primary"
              style={{
              backgroundColor:"#268ae1"
  
              }}
              className={classes.button}
              onClick={() => {
                localStorage.setItem('client_id',item.arr_cl_id)
                localStorage.setItem('client_Title',item.arr_cl_name)
                // console.log("obj "+  client_id:item.arr_cl_id,)
                // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientSpecDiscPerCallsUp_api",
                fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_ClientSpricesCallsUp",
  
                {
                  method:"POST",
                  'Content-Type':"application/json",
                  body: JSON.stringify({
                    // branch_id:1,
                    client_id:item.arr_cl_id,
                  
                    // user_id:localStorage.getItem('user_id')
                  })
                }).then((res) => res.json()).then((response) => {
  console.log("check list of client spec price"+JSON.stringify(response))
      setProductList(response)
      setClientSelect(true)
  
                })
  
          
            setOpen(false)
              }}
            >
              Select
            </Button>
          ),
        };
  
        data.push(obj);
      });
      setClientList(data);
    
    
      })
    }else{
      fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_ClientSpricesCallsUp",
  
      {
        method:"POST",
        'Content-Type':"application/json",
        body: JSON.stringify({
          // branch_id:1,
          client_id:localStorage.getItem('user_id'),
        
          // user_id:localStorage.getItem('user_id')
        })
      }).then((res) => res.json()).then((response) => {
  console.log("check list of client spec price"+JSON.stringify(response))
  setProductList(response)
  
      })
  
    }
    
      // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",
  
      // {
      //   method:"POST",
      //   'Content-Type':"application/json",
      //   body: JSON.stringify({
      //     // branch_id:1,
      //     branch_id:parseInt(localStorage.getItem('branch_id')),
  
      //     category_id:0,
      //     user_id:localStorage.getItem('user_id')
  
      //   })
      // }).then((res) => res.json()).then((response) => {
      // console.log("check Products response "+JSON.stringify(response))  
      // setProductList(response)
   
  
      // }).catch((err)=>{
      //   console.log("err "+err)
  
      //  alert("Session Expired "+err)
      //  localStorage.clear()
      // })
  
  
    },[])
 
  
  return (
    <>
      <Paper className={classes.root} style={{}}>
      {/* <h1 style={{color:"red"}}> hshshsahhdfahdsajhsdfagh</h1> */}
        <div style={{ padding: '1em'}}>
        {width<500?
          <div onClick={()=>{
            // history.push('/');   
          }} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={image} 
style={{height:80,width:width<500?300:400}}
 ></img>
 </div>
 :<></>}


<GridContainer style={{ padding: '2em' }}>
<GridItem xs={12} sm={12} md={3} lg={2} style={{marginLeft:width<500?0:-10}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
// 14-aug-2024 for new api condition jo 14-aug ki sham ko milli clientid b deni hai qr code scanner me 

        if(localStorage.getItem("client_id")||localStorage.getItem("user_type")=="client" ){
        if(showQrScanner){
          setshowQrScanner(false)
        }else{
        setshowQrScanner(true)
        }
      }else{
        alert("Please Select Client first only for admins !")
      }
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "13.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#268ae1",
            marginTop:width<970?5:0

           }}>
<CropFreeIcon style={{width:20,height:20,color:"white",}}></CropFreeIcon>
               {/* Show Scanner  */}
               {showQrScanner?"Close Scanner":"Qr Code Scanner"} 
           </Button>
           
              {/* <h4>Qr / Barcode  scanner </h4> */}
              {/* <div id="reader"></div> */}
              {scanResult?<div>success:{scanResult}</div>:
              <div style={{display:showQrScanner?"":"none"}} id="reader" ></div>
              }
              <div style={{display:showQrScanner?"":"none"}} id="reader" ></div>
              
</GridItem>
{/* 19-aug-2024 changes from client */}
{localStorage.getItem("user_type")=="user"?<>
<GridItem xs={12} sm={6} md={3} lg={2} style={{}}>

<FormGroup style={{
              marginLeft:width<500?"": "5em"
            }}>
              <b style={{fontWeight:"bold"}}>Period Apply</b>
              <FormControlLabel
              labelStyle={{color: 'red'}}
                control={<Checkbox color="success" style ={{
                  color: "#268ae1",
                }}checked={ifPeriodApplyYes} onChange={(e) => {
                  setifPeriodApplyYes(e.target.checked)
                  if (e.target.checked) {
                   setifPeriodApplyNo(false)
                   setnoRecordFound(false)
                  }
                }} />}
                label="Yes"
              />
              <FormControlLabel
                control={<Checkbox color="success"  style ={{
                  color: "#5794ca",
                }} checked={ifPeriodApplyNo} onChange={(e) => {
                  setifPeriodApplyNo(e.target.checked)
                  if (e.target.checked) {
                   setifPeriodApplyYes(false)
                   setnoRecordFound(false)

                  }
                }} 
                />}
                label="No"
              />
            
            
              
            </FormGroup>
</GridItem>
          <GridItem xs={12} sm={6} md={3} lg={2} style={{marginTop:width<500?"4%":width<600?"2%":0}}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {localStorage.getItem('if_admin')=="true"?
              <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              label="From"
              format="MM/dd/yyyy"
              value={fromdate}
              // 20-june-2024 for client only for user its not apply 
  maxDate={from}
  // maxDate={new Date(from.setDate(from.getDate() ))}
  // minDate={new Date(from.setDate(from.getDate() - 1))}

              InputAdornmentProps={{ position: "start" }}
              onChange={date => {
                setFromDate(date)
                setnoRecordFound(false)
              }
              }
            />:
            <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="MM/dd/yyyy"
                value={fromdate}
                // 20-june-2024 for client only for user its not apply 
    maxDate={from}
    // maxDate={new Date(from.setDate(from.getDate() ))}
    minDate={new Date(fromNew.setDate(fromNew.getDate() - 10))}

                InputAdornmentProps={{ position: "start" }}
                onChange={date => {
                  setFromDate(date)
                  setnoRecordFound(false)
                }
                }
              />
              }
            
            </MuiPickersUtilsProvider>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={2} style={{marginTop:width<500?"4%":width<600?"2%":0}}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
{localStorage.getItem('if_admin')=="true"?
  <KeyboardDatePicker
    variant="inline"
    inputVariant="outlined"
    label="to"
    format="MM/dd/yyyy"
    value={todate}
            // 20-june-2024 for client only for user its not apply 
    maxDate={from}
    // maxDate={new Date(from.setDate(from.getDate()+1 ))}
    // minDate={new Date(from.setDate(from.getDate() -1))}
    InputAdornmentProps={{ position: "start" }}
    onChange={date => {setToDate(date)
      setnoRecordFound(false)
    
    }
  }
  />:
  <KeyboardDatePicker
    variant="inline"
    inputVariant="outlined"
    label="to"
    format="MM/dd/yyyy"
    value={todate}
            // 20-june-2024 for client only for user its not apply 
    maxDate={from}
    // maxDate={new Date(from.setDate(from.getDate()+1 ))}
    minDate={from}
    InputAdornmentProps={{ position: "start" }}
    onChange={date => {setToDate(date)
      setnoRecordFound(false)
    
    }
  }
  />}
</MuiPickersUtilsProvider>
</GridItem>
<GridItem xs={12} sm={6} md={3} lg={2} style={{marginTop:width<500?"4%":width<600?"2%":0}}>


<Button
           variant="contained"
           color="primary"
           onClick={() => {
            // start #############
      // const test=[{invoice_no:"",branch_id:1,invoice_date:"2024-01-18T10:28:58.265Z",invoice_remarks:"",user_id:"04",user_name:"DJ",client_id:"01CL00724",client_title:"1 10 MART ",inv_amount:50.97,inv_discount_value:0,inv_discount_special_percent:0,inv_discount_special_value:0,invoice_net_value:50.97,refund_amount:0,refund_line_discount:0,refund_special_discount_percent:0,refund_special_discount_value:0,refund_net_value:0,ArrEntrySequence:[1,2,3],ArrSequenceNo:[1,2,3],ArrItemSystemID:[605,3825,2892],ArrItemCode:["","",""],ArrItemName:[" T-Shirts Plain (1/6ct)","Baked Candles Terpene Infused 13oz (1ct)","Blunt Effects AF Spray (1/18ct)"],ArrItemQty:[1,1,1],ArrItemRate:[14.99,2.49,33.49],ArrItemAmount:[14.99,2.49,33.49],ArrItemUnitCost:[14.99,2.49,33.49],ArrDiscountPercent:[0,0,0],ArrDiscountValue:[0,0,0],ArrRefundQty:[0,0,0],ArrRefundValue:[0,0,0]}]
      const test=[{"invoice_no":"","branch_id":1,"invoice_date":"2024-01-18T10:59:45.321Z","invoice_remarks":"","user_id":"04","user_name":"DJ","client_id":"01CL00724","client_title":"1 10 MART ","inv_amount":21.47,"inv_discount_value":0,"inv_discount_special_percent":10,"inv_discount_special_value":2.15,"invoice_net_value":19.32,"refund_amount":0,"refund_line_discount":0,"refund_special_discount_percent":0,"refund_special_discount_value":0,"refund_net_value":0,"ArrEntrySequence":[1,2,3],"ArrSequenceNo":[1,2,3],"ArrItemSystemID":[605,145,2611],"ArrItemCode":["","",""],"ArrItemName":[" T-Shirts Plain (1/6ct)"," TP GP 459510 ( 1CT )-ODR","10mm China male Slider 3.5\" ( 1CT )-ODR"],"ArrItemQty":[1,1,1],"ArrItemRate":[14.99,4.99,1.49],"ArrItemAmount":[14.99,4.99,1.49],"ArrItemUnitCost":[14.99,4.99,1.49],"ArrDiscountPercent":[0,0,0],"ArrDiscountValue":[0,0,0],"ArrRefundQty":[0,0,0],"ArrRefundValue":[0,0,0]}]
      
      // end ####################
      setnoRecordFound(false)

            var obj={
              user_type:localStorage.getItem('user_type'),

              // branch_id:2,
              user_id:localStorage.getItem('user_id'),
              // user_id:"11",
        
              date_from:fromdate.toISOString(),
              date_till:todate.toISOString(),
              if_period_apply:ifPeriodApplyYes?true:false
            }
            console.log("check obj "+JSON.stringify(obj))
            // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingList",
            fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_INV_ModifyListCalls",

            {
              method:"POST",
              'Content-Type':"application/json",
              body: JSON.stringify(obj)
            }).then((res) => res.json()).then((response) => {
            console.log("check Products response "+JSON.stringify(response)) 
            if (response[0].invoice_id!=""){
            var DataInvoice=[]
            response.map((item) => {
              // console.log(item)
             
              var obj = {
                invoice_id: item.invoice_id,
                // invoice_date:(new Date(item.invoice_date).getDate()) + "/" + (new Date(item.invoice_date).getMonth() + 1) + "/" + (new Date(item.invoice_date).getFullYear()) ,
                invoice_date:(new Date(item.invoice_date).getMonth() + 1) + "/" + (new Date(item.invoice_date).getDate())+ "/" + (new Date(item.invoice_date).getFullYear()) ,
              
                client_title: item.client_title,
                invoice_net_value: parseFloat(item.invoice_net_value).toFixed(2)+"$",
                invoice_remarks: item.invoice_remarks,
               
                select: (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                    backgroundColor:"#268ae1"
        
                    }}
                    className={classes.button}
                    onClick={() => {
                      // alert("working on it")
                      setLoading(true)
                      // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingContent",
                      // var obj={
                      //   invoice_id:item.invoice_id,
                      //   user_id:localStorage.getItem('user_id'),
                      //   client_id:""
                      // }
                      // console.log(obj)
                      fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_InvoiceLoadsContent",

                      {
                        method:"POST",
                        'Content-Type':"application/json",
                        body: JSON.stringify({
                         invoice_id:item.invoice_id,
              user_id:localStorage.getItem('user_id'),
              client_id:""
                       
                        })
                      }).then((res) => res.json()).then((response) => {
                      
                      console.log("check InvoiceModifyLoadingContent response "+JSON.stringify(response))
                    

//                       console.log("check InvoiceModifyLoadingContent response "+JSON.stringify(response[0].ArrEntrySequence))
      

//           //  start #############
          var ArrayData=[]
          JSON.parse(response[0].ArrItemSystemID).map((value,index)=>{
       
              console.log("check map  "+JSON.stringify(value))
                            const obj ={
                sn:index+1,
                systemId: JSON.parse(response[0].ArrItemSystemID)[index],
                code: JSON.parse(response[0].ArrItemCode)[index],
                itemName:JSON.parse( response[0].ArrItemName)[index],
                rate:JSON.parse(response[0].ArrItemRate)[index],
                qty:JSON.parse(response[0].ArrItemQty)[index],
                rfd:0,
                rfdamount:0,
               
                
                amount:JSON.parse(response[0].ArrItemRate)[index],
                discount:JSON.parse(response[0].ArrItemDisc_perc)[index],
                discountValue:JSON.parse(response[0].ArrItemDisc_value)[index],
                netvalue:JSON.parse(response[0].ArrItemAmount)[index],
                requestedQuantity:1

                // buyingPrice:JSON.parse(value.ArrItemUnitCost)[index],
              }
             
              console.log("check map inner  "+JSON.stringify(obj))

           
//               JSON.parse(value.ArrEntrySequence).map((chk,index)=>{
//               console.log("check map INNER  "+chk+"INDEX"+index)
//               // console.log("check map QTY  "+value.ArrItemQty[index]+"INDEX"+index)
              
//               const obj ={
//                 sn:JSON.parse(value.ArrSequenceNo)[index],
//                 systemId: JSON.parse(value.ArrItemSystemID)[index],
//                 code: JSON.parse(value.ArrItemCode)[index],
//                 itemName:JSON.parse( value.ArrItemName)[index],
//                 rate:JSON.parse(value.ArrItemRate)[index],
//                 qty:JSON.parse(value.ArrItemQty)[index],
//                 rfd:JSON.parse(value.ArrRefundQty)[index],
//                 rfdAmount:JSON.parse(value.ArrRefundValue)[index], 
//                 amount:JSON.parse(value.ArrItemAmount)[index],
//                 disc:JSON.parse(value.ArrDiscountPercent)[index],
//                 discount:JSON.parse(value.ArrDiscountValue)[index],
//                 netvalue:JSON.parse(value.ArrItemAmount)[index],

//                 buyingPrice:JSON.parse(value.ArrItemUnitCost)[index],
//               }

ArrayData.push(obj)

//               })
// // for special discount 
// setSpecialDiscountPer(value.inv_discount_special_percent)
// setSpecialDiscountValue(value.inv_discount_value)
// setrfdSpecialDiscountPer(value.refund_special_discunt_percent)
// setrfdSpecialDiscountValue(value.refund_special_discount_value)
// // for other variable
// setInvoiceId(value.invoice_id)
// setbranchId(value.branch_id)
// setinvoiceDate(value.invoice_date)
// setclientId(value.client_id)
// setclientTitle(value.client_title)


            })
            console.log("check Cart Array"+JSON.stringify(ArrayData))
//             setcount(ArrayData[0].sn+1)

            dispatcchUserEvents("UpdateInvoice",{updateArray:ArrayData})

            
            setopenPreInvoice(false)
console.log("check invoice id ")
console.log(response[0].invoice_id)
            dispatcchUserEvents("InvoiceId",{invoiceId:response[0].invoice_id})
            localStorage.setItem('client_id',response[0].client_id)
              localStorage.setItem('client_Title',response[0].client_title)
            
         
                
                      setLoading(false)
                      history.push("/Invoice")
                     })
                     .catch((e)=>{
console.log(e)
alert("sorry data not fetch from backend !"+e)
setLoading(false)

                     })
                     
                    }}
                  >
                    Select
                  </Button>
                ),
              };
        
              DataInvoice.push(obj);
            });
            
            setPreInvoiceData(DataInvoice)
          }else{
            setnoRecordFound(true)
          }
        
            })
       
           
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "14.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#268ae1"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
<SearchIcon  style={{width:20,height:20,color:"white",marginRight:5}}></SearchIcon >

             Search
           </Button>
          {noRecordFound &&  <h4><b style={{color:"red"}}>No Invoice Found</b></h4>}
</GridItem>
</>:<></>}

{/* <GridItem xs={6} sm={6} md={3} lg={3}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
  <KeyboardDatePicker
    variant="inline"
    inputVariant="outlined"
    label="From"
    format="dd/MM/yyyy"
    value={todate}
    InputAdornmentProps={{ position: "start" }}
    onChange={date => setToDate(date)}
  />
</MuiPickersUtilsProvider>
</GridItem> */}


{/* <GridItem xs={6} sm={6} md={3} lg={3}>

<FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={role}
          onChange={(e)=>{
       setRole(e.target.value)
          }}
        >
        <MenuItem  value={1}>JTEL</MenuItem>
          <MenuItem value={2}>Admin</MenuItem>
         
        </Select>
      </FormControl>
</GridItem> */}

         </GridContainer>
         {loading?  <center>
            <ReactLoading type={'spinningBubbles'} color={'#196c90'} height={'10em'} width={'10em'} />
          </center>:
          
          <MaterialTable
            columns={[
              { field: "select", title: "Select" },
              { field: "invoice_id", title: "InvoiceId",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_id}</p>
              }
             },
              { field: "invoice_date", title: "Date",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_date}</p>
              }},
              { field: "client_title", title: "Client Title",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.client_title}</p>
              } },
              { field: "invoice_net_value", title: "Net Value",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_net_value}</p>
              } },
              { field: "invoice_remarks", title: "Remarks",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_remarks}</p>
              } },
             



            ]}
            style={{
              // marginLeft:width<960?0: 10,
              border:" 2px solid #268ae1",
              
            }}
            options={{
              // paging: false,
              headerStyle: {
                backgroundColor: "#268ae1",
                color: "#FFF",
                // fontSize: "17px",
                // textAlign: "center",
                fontWeight: "bold",
                // minHeight:"50em"
              },
            
              // rowStyle: rowData => {
              //   // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
              //   //   return {};
              //   // }
              //   // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
              //   return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
              // };
              // }
            }}
            data={PreInvoiceData}
            // title={"Invoice Data"}
            title={<span style={{ color: '#268ae1',fontWeight:"bold" }}>{localStorage.getItem("user_type")=="user"?"Invoice Edit":"Scan Invoice Data"}</span>}
          />
        }
              <GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"end",alignItems:"center",marginTop:10}}>
      <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
            //  SeveRecordHandler()
            // alert("check data "+remarks+from.toISOString())
            // window.location.reload();
dispatcchUserEvents("ClearData")
            
history.push("/")
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "12.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#268ae1"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
            Back to Home
           </Button>
           </GridItem>
          

        </div>
        
       
       


      </Paper>
     
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOpen(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Please Select a Client</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <MaterialTable
            columns={[
              { field: "select", title: "Select" ,
            },
             { field: "clientTitle", title: "Client's Title",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.clientTitle}</p>
             } },
             { field: "phone", title: "Phone",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.phone}</p>
             }},
             { field: "city", title: "City",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.city}</p>
             } },
             { field: "state", title: "State",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.state}</p>
             } },
             { field: "zip", title: "Zip",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.zip}</p>
             } },
             { field: "address", title: "Address",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.address}</p>
             } },
              


            ]}
            style={{
              // marginLeft:width<960?0: 10,
              border:" 2px solid #268ae1",
              
            }}
            options={{
              // paging: false,
              headerStyle: {
                backgroundColor: "#5794ca",
                color: "#FFF",
                // fontSize: "17px",
                // textAlign: "center",
                fontWeight: "bold",
                // minHeight:"50em"
              },
            
              // rowStyle: rowData => {
              //   // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
              //   //   return {};
              //   // }
              //   // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
              //   return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
              // };
              // }
            }}
            data={ClientList}
            title={"Clients"}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOpen(false)
              window.close();
         

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
      {/* for prev invoice selection */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={openPreInvoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setopenPreInvoice(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

<h4 className={classes.modalTitle}><b>Select Previous Invoice</b></h4>

        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              if(!invoiceId){
                history.push("/Invoice")
                              }
              setopenPreInvoice(false)
              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
            {/* foer qr code  */}
            <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'sm'}
        fullWidth={true}
        open={openQrCodeModal}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setopenQrCodeModal(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h3 className={classes.modalTitle}><b>View Image</b></h3>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
           <GridContainer style={{marginTop:"2%"}}>
                     <GridItem sm={12} md={12} lg={12} >
                    
                      <div style={{  display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                         {scanResult?<div><h3> success: </h3><h4>{scanResult}</h4>
                        {console.log("check it")}

                        {/* {console.log(scanResult)} */}
{/* {autoAddItemIntoCart()} */}
<Button style={{backgroundColor:"#268ae1",color:"white"}}
            onClick={() => {
              // 4-june-2024
              
              // rerenderQrScanner()
              // autoAddItemIntoCart()
              setscanResult("")
              setopenQrCodeModal(false)
              rerenderQrScanner()
             

            }}
            color="danger"
            simple
          >
            Continue  
                    </Button>

                         </div>
                         
                         :<>
              <div id="reader">
                <h4>{scanResult}</h4>
             
              {/* <img src={mobile}></img> */}
                
                
              </div>
              <img src={mobile} height={150} width={75}></img>
              <Button style={{backgroundColor:"#268ae1",color:"white"}}
            onClick={() => {
              // 4-june-2024
              
              // rerenderQrScanner()
              // autoAddItemIntoCart()
              setscanResult("")
              setopenQrCodeModal(false)
             

            }}
            color="danger"
            simple
          >
            Continue To Scan 
                    </Button>
               
               </>
              }
              
               
           

                        {/* <img src={selectedReport}></img> */}
                     {/* <iframe  style={{alignSelf:"center"}}  src={selectedReport}  width={500} height={400}></iframe> */}
                     </div>
                     </GridItem>
                     </GridContainer>


        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenQrCodeModal(false)
              // rerenderQrScanner()

              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
