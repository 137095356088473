// BottomTabs.js
import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles({
    root: {
      color: ' #878b8c', // default color
      '&$selected': {
        color: '#268ae1', // color when selected
      },
    },
    selected: {},
  });

const BottomTabs = ({iamIn}) => {
  const [value, setValue] = React.useState(iamIn);
  const history = useHistory();
  const classes = useStyles()
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      // case 0:
      //   history.push('/');
      //   break;
      case 0:
        history.push('/Invoice');
        break;
        case 1:
            history.push('/InvoiceUpdate');
            break;
      case 2:
        history.push('/InvoicePdf');
        break;
        case 3:
            // history.push('/InvoicePdf');
           
          
              console.log("ok hua check ")
              // localStorage.removeItem('user_id')
              // localStorage.removeItem('user_name')
              localStorage.clear();

              history.push("/login")
  
              // location.reload();
          
            
            
  
             
            
            break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation value={value} onChange={handleChange} showLabels style={{ 
        position: 'fixed', 
        bottom: 0, 
        width: '100%', 
        zIndex: 1000, 
        backgroundColor: 'white' ,
        
        borderTop:"1px solid #268ae1"

      }}>
      {/* <BottomNavigationAction label="Home" icon={<HomeIcon     />}  classes={{ root: classes.root, selected: classes.selected }}  /> */}
      <BottomNavigationAction label="Inv New" icon={<ReceiptIcon />}    classes={{ root: classes.root, selected: classes.selected }}  />
      <BottomNavigationAction label="Inv Edit" icon={<EditIcon />}    classes={{ root: classes.root, selected: classes.selected }}  />
     
      <BottomNavigationAction label="Inv PDF" icon={<PictureAsPdfIcon />}     classes={{ root: classes.root, selected: classes.selected }} />
      <BottomNavigationAction label="Logout" icon={<ExitToAppIcon />}    classes={{ root: classes.root, selected: classes.selected }}  />

    </BottomNavigation>
  );
};

export default BottomTabs;
