// import React from "react";
import React,{useState,useContext,useEffect}from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link,useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";
import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
import SectionExamples from "./Sections/SectionExamples.js";
import SectionDownload from "./Sections/SectionDownload.js";
import FreeHS_Msg from './FreeHS_Msg'

import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import TeamSectionNew from "./Sections/TeamSectionNew.js";
import SlickCarousel from 'components/SlickCarousel/SlickCarousel';

import WorkSection from "./Sections/WorkSection.js";
import image2 from "../../images/MM/User_icon-removebg-preview.png"
// import imagefooter from "../../images/MM/Footer.jpg"
import imagefooter from "../../images/A1 Wholesale/02 Address Lines.png"

import image from "../../images/A1 Wholesale/HeadingNew.jpeg"
import imagebutton1 from "../../images/A1 Wholesale/Button1.png"
import imagebutton2 from "../../images/A1 Wholesale/Button2.png"
import imageButtonForRetailor from "../../images/A1 Wholesale/ButtonNewForRetailer.jpeg"
import imagebutton3 from "../../images/A1 Wholesale/Button3.png"

import imagebutton4 from "../../images/A1 Wholesale/Button4.png"


import styles from "assets/jss/material-kit-react/views/components.js";
import { isMobile } from 'react-device-detect';
import BottomTabs from './BottomTab';
import AOS from 'aos'




const useStyles = makeStyles(styles);
export default function Components(props) {
  const { width } = useWindowDimensions();
  const [role, setRole]= React.useState(1)

  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();
  useEffect(()=>{
    AOS.init({
      duration : 2000
    });
    // alert('This is app js')
  },[])

  useEffect(() => {
    console.log("hello")
    if(localStorage.getItem('user_name')  ){
      // window.location.href="https://mpl-labs.pk"
      
     }
     else{
      history.push('/Login');  
     }
    // if(localStorage.getItem("role")=="Panel"){
    //   var email=localStorage.getItem("patient_id")
    // var password=localStorage.getItem("id")
    // var role =localStorage.getItem("role")
    // var log='ID'
    // fetch('http://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
    // fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
  
    // method: 'POST',
    //   'Content-Type':"application/json",
    //   body:'vUID=' + email + '&vPW=' + password + '&vRole=' + role + '&vLoginType=' + log + "&vRandom=" + 21211
    // }).then((res)=>res.text()).then( async(resss)=>{
    //   console.log("LabReports Check auto logout"+resss)
     
    //   var res=JSON.parse(resss)
      // if(res.Result){
      //   console.log("Resulte true Login Cridential "+res.Result)
      // }
      // else{
      //   console.log("Resulte false Login Cridential "+res.Result)
      //   alert("Session Expired Please Login Again ..... !")
      //   setTimeout(() => {
      //     localStorage.clear()
      // window.location.href = "https://mpl-labs.pk/Login"
      // // window.location.href = "http://localhost:3000/Login"

          
      //   }, 500);
      // }
     

    
    // })
    // }
//  end 06-june-2023
setRole(parseInt(localStorage.getItem('branch_id')))
  },[])
  
  return (
    <>
    <div style={{
      
      // backgroundColor:"#cb1a2c"
      backgroundColor:"#268ae1"

      }}>
        {width<500?<div style={{height: 10,width:"100%",backgroundColor:"#268ae1"}}>

        </div>:
      <Header
        brand="Metropole Laboratories Private Limited"
        //rightLink me headerLink pass kr rhe hn jo show ho rhy hn Home,Services,About Us etc on the base of login patient ,admin etc 
        rightLinks={<HeaderLinks />}
        fixed
        // 21-june -2023
        // color="transparent"
        color="white"
        changeColorOnScroll={{
          height: 800,
          color: "black"
        }}
        {...rest}
      />}
     {/* <div style={{marginTop:'8em'}}> */}
      {/* when new image recieved 26-aug-2023 */}
      {width<4000?
      
            <></>
            :
            <div style={{width:'100%',
              marginTop:width<500?"7em":width<950?"5.5em":width<1200?"5.0em":width<1290?"6.0em":'6.5em',
              backgroundColor:"#268ae1"
             }}
              >
              
              <SectionCarousel />
             
                </div>
      }
      {/* 3-aug new tab for mobile */}
      {width<4000?
     <div style={{height:"100%",width:"100%",display:"flex",flexDirection:"column",position:"fixed",backgroundColor:"#268ae1"}}>

     <div style={{height:"100%",width:"98%",display:"flex",flexDirection:"column",position:"fixed",backgroundColor:"white",marginLeft:"1%",marginRight:"1%"}}>
{/* <div style={{height:width<500?"98%":"83%",width:width<500?"98%":"99%",display:"flex",flexDirection:"column",position:"fixed",backgroundColor:"white",marginLeft:width<500?"1%":"0.5%",marginRight:width<500?"1%":"0.5%",marginTop:width<500?"2%":"0.5%"}}> */}
     {width<500?<></>:
     <div style={{height:"15%",width:"100%",}}>
     {/* <img src={image} 
style={{height:80,width:width<500?250:400}}
 ></img> */}
     </div>
     }
     <div style={{height:"15%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
     <img src={image} 
// style={{height:80,width:width<500?300:400}}
style={{height:width<500?80:100,width:width<500?300:350}}
 ></img>
     </div>
     {/* whole screen start */}
     <div style={{height:width<500?"73%":"60%",width:"100%"}}>
      {/* first portion image and name */}
     <div style={{height:"20%",width:"100%",display:"flex",flexDirection:"row"}}>
     <div style={{height:"100%",width:width<500?"40%":"45%",display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
     <img src={image2} style={{height:80,width:100}} ></img>
      </div>
      <div style={{height:"90%",width:width<500?"60%":"55%",display:"flex",justifyContent:"center",alignItems:"flex-start",flexDirection:"column",marginTop:"2%"}}>
    <div>
      <p style={{fontFamily:"cursive",fontSize:25,color:"#268ae1"}} > Welcome !</p>
      </div>
      <div>
      <p style={{marginTop:-5,fontFamily:"cursive",fontSize:25,color:"black"}}> {localStorage.getItem("user_name")}</p>
      </div>
      </div>
     </div>
     {/* 2nd portion buttons */}
     
     <div style={{height:"80%",width:"100%",display:"flex",flexDirection:"column",}} >
      <div  onClick={() => {
          history.push('/Invoice');              

        }} style={{height:"20%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <img src={imagebutton1} 
      style={{height:width<500?65:60,width:width<500?210:200}} 
      ></img>

     {/* <Button
        class="button-72" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/Invoice');              

        }}
        >
      
          <b>Invoice New</b>
        </Button> */}
        </div>
        <div  onClick={() => {
          history.push('/InvoiceUpdate');               

        }} style={{height:"20%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={localStorage.getItem("user_type")=="user"? imagebutton2:imageButtonForRetailor} 
    style={{height:width<500?65:60,width:width<500?210:200}} 
      ></img>
     {/* <Button
        class="button-72" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/InvoiceUpdate');               

        }}
        >
         
        <b>Invoice Edit</b>
        </Button> */}
        </div>
        <div   onClick={() => {
          history.push('/InvoicePdf');            

        }} style={{height:"20%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={imagebutton3} 
     style={{height:width<500?65:60,width:width<500?210:200}} 
      ></img>
     {/* <Button
        class="button-72" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
        }}
        onClick={() => {
          history.push('/InvoicePdf');            

        }}
        >
       
           <b>Invoice Pdf</b>
        </Button> */}
        </div>
        <div  onClick={() => {
          let text;
          if (window.confirm("Are You sure To Logout ?") == true) {
            console.log("ok hua check ")
            // localStorage.removeItem('user_id')
            // localStorage.removeItem('user_name')
            

            localStorage.clear();
            history.push("/Login")
            // location.reload();
        
          } else {
            console.log("check "+text)
            console.log("cancel hua hua check ")

           
          }
        }} style={{height:"20%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={imagebutton4} 
     style={{height:width<500?65:60,width:width<500?210:200}}  
      ></img>
     {/* <Button
        class="button-72" role="button"
        // className={"button"}
          style={{
            // backgroundColor:'#cb1a2c',
            // backgroundColor:'white', 
            // borderColor:"#cb1a2c",
            // borderWidth:"2px",
            // borderStyle:"solid"


          // boxShadow: "10px 10px 5px lightblue"
          // boxShadow:" 5px 5px #faa931"
          padding:"14px 45px"
        }}
        onClick={() => {
          let text;
          if (window.confirm("Are You sure To Logout ?") == true) {
            console.log("ok hua check ")
            // localStorage.removeItem('user_id')
            // localStorage.removeItem('user_name')
            history.push("/")

            localStorage.clear();
            // location.reload();
        
          } else {
            console.log("check "+text)
            console.log("cancel hua hua check ")

           
          }
        }}
        >
        
          <b>Logout</b>
        </Button> */}
        </div>
        <div style={{height:"20%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",}}>
        <img src={imagefooter} 
        style={{height:width<500?50:80,width:width<500?300:400}}
         ></img>
        </div>
     </div>
     </div>
     {/* <div style={{height:"15%",width:"100%",backgroundColor:"red"}}>a</div> */}

     {/* <div style={{height:"20%",width:"100%",backgroundColor:"red"}}>a</div>
     <div style={{height:"20%",width:"100%",backgroundColor:"green"}}>b</div>
     <div style={{height:"20%",width:"100%",backgroundColor:"red"}}>a</div> */}

    



     </div>
     </div>:<></>
    }   
     
    
     <div style={{width:'100%',
    //  marginTop:width<500?"6em":width<950?"5.5em":width<1200?"5.0em":width<1290?"6.0em":'7.2em',
    // paddingTop:width<230?"38.5%":width<260?"36.5%":width<290?"34.5%":width<320?"32.5%":width<350?"30.5%":width<410?"32.5%":width<420?"25.5%":width<440?"24.5%":width<460?"22.5%":width<500?"20.5%":width<530?"43.5%":width<550?"41.5%":width<590?"39.5%":width<620?"37.5%":width<650?"35.5%":width<680?"33.5%":width<718?"31.5%":width<740?"30.5%":width<767?"29.5%":width<800?"28.5%":width<850?"26.5%":width<880?"25.5%":width<921?"24.5%":width<950?"23.5%":width<1000?"22.5%":width<1050?"21.5%":width<1100?"20.5%":width<1120?"19.5%":width<1160?"19%":width<1180?"18.5%":width<1220?"18%":width<1253?"17.5%":width<1275?"17%":width<1300?"18.5%":width<1350?"18%":width<1400?"17.5%":width<1440?"17%":"18%",
    // paddingBottom:width<230?"63.5%":width<260?"61.5%":width<290?"59.5%":width<320?"57.5%":width<350?"55.5%":width<410?"57.5%":width<420?"51.5%":width<440?"49.5%":width<460?"48.5%":width<500?"45.5%":width<530?"43.5%":width<550?"41.5%":width<590?"39.5%":width<620?"37.5%":width<650?"35.5%":width<680?"33.5%":width<718?"31.5%":width<740?"30.5%":width<767?"29.5%":width<800?"28.5%":width<850?"26.5%":width<880?"25.5%":width<921?"24.5%":width<950?"23.5%":width<1000?"22.5%":width<1050?"21.5%":width<1100?"20.5%":width<1120?"19.5%":width<1160?"19%":width<1180?"18.5%":width<1220?"18%":width<1253?"17.5%":width<1275?"17%":width<1300?"18.5%":width<1350?"18%":width<1400?"17.5%":width<1440?"17%":"18%",
   
    backgroundColor:"#268ae1"
    }}
     >
      {/* {localStorage.getItem('admin_rights')=="true"?
         <div style={{width:"100%", 
         position:"fixed"

         }}>
        <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={role}
          onChange={(e)=>{
       setRole(e.target.value)
       console.log(e.target.value)
       localStorage.setItem('branch_id',e.target.value)

          }}
        >
        <MenuItem  value={1}>JTEL</MenuItem>
          <MenuItem value={2}>ROANOKE</MenuItem>
         
        </Select>
      </FormControl>
      </div>
      :<></>} */}
     
     <h1 style={{color:"#268ae1"}}>Jtell</h1>
    {/* {width} */}
   
   
       </div>
     
    
   
{/* these classes.main are call from import styles from "assets/jss/material-kit-react/views/components.js"; */}
      <div  className={classNames(classes.main, classes.mainRaised)}
       style={{
        marginTop:width<500?-90:width<600?-90:width<700?-90:width<800?-90:width<900?-90:width<1000?-90:width<1100?-90:width<1200?-90:-88
      }}
       >
        {/* {width} */}
        {width<4000 ?
      <></>
:
<ProductSection />

        }
     

      </div>
      {width<4000?<></>:
      <Footer />

      }
      {isMobile &&  <BottomTabs iamIn={0} />}

    </div>
</>
  );
}
