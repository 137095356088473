import React, { useEffect, Fragment,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
//24-march-2023 for acs desc
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import useWindowDimensions from "../../useWindowDimensions";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


// import Grid from "@material-ui/core/Grid";


import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import { NavLink } from "react-router-dom";
import { Link,useHistory } from "react-router-dom";



import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';
// import Invoice from './PDF/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';
import image from "../../images/A1 Wholesale/HeadingNew.jpeg"

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from '@date-io/date-fns';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
// import CovidTemplate from './Covid_PDF/CovidTemplate'
// import TemplateCertificate from './TemplateCertificate/TemplateCertificate'
import GroupIcon from '@material-ui/icons/Group';
import RemoveRedEyeOutlinedIcon from '@material-ui//icons/RemoveRedEyeOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import CertificateTemplate from './Certificate/CertificateTemplate'
import Search from '@material-ui/icons/Search';

// import SignCovidTemplate from './Sign_Covid_PDF/SignCovidTemplate'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import Covid_AntiGen from './Covid_AntiGen/CovidTemplate'
import { bool } from 'prop-types';
import { ProductContex } from '../../ContextStore.js';
import { Html5QrcodeScanner } from 'html5-qrcode';
import mobile from "../../images/MM/mobile.png"


import TextField from '@material-ui/core/TextField'
import "./Lab_Tests.css"
import CropFreeIcon from '@material-ui/icons/CropFree';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id = localStorage.getItem('patient_name') + ""
var invoice = id.toLowerCase().includes('mpl-') ? [
]
  : [
    { id: 'certificate', label: 'Certificate' },
    // { id: 'invoice', label: 'View Invoice' },
    { id: 'refresh', label: 'Refresh Report' }
  ]

var ksaInfo= localStorage.getItem('patient_id')=="AR-01033" ?
[
  { id: 'Airline', label: 'Airline' },
  { id: 'Airport', label: 'Airport' }
]:
[
  { id: 'code', label: 'Test Code' },
  { id: 'branch', label: 'Branch' }
]
  //21-march-2023 invoices show for panel and patient

var panelInvoice= localStorage.getItem('role')=="Panel" ?
[
    { id: 'invoice', label: 'View Invoice' },

]:[]


// const columns =
//   localStorage.getItem('role') == 'Admin' ?
//     [
//      { id: 'mr_number', label: 'MPL MR No' },
//       { id: 'id', label: 'MPL ID' },
//      //18-feb-2023 
//      { id: 'reference_no', label: 'Panel Ref No' },
//     { id: 'name', label: 'Patient Name' },
//     { id: 'title', label: 'Test Name' },
//     { id: 'Rep_TimeDate', label: 'Reporting Time' },
//     { id: 'branch', label: 'Branch' },
//     { id: 'status', label: 'Status' },
//     { id: 'download', label: 'View Single Test Report' },
//     { id: 'viewAllReports', label: 'View All Tests Report' },

//     ...invoice,

//     ]
//     :
//     [
//     { id: 'mr_number', label: 'MPL MR No' },
//     { id: 'id', label: 'MPL ID' },
//     //18-feb-2023 
//     { id: 'reference_no', label: 'Panel Ref No' },


//     { id: 'name', label: 'Patient Name' },
//     { id: 'title', label: 'Test Name' },
//     { id: 'Rep_TimeDate', label: 'Reporting Time' },
//     ...ksaInfo,
//     { id: 'status', label: 'Status' },
//     { id: 'download', label: 'View Single Test Report' },
//     { id: 'viewAllReports', label: 'View All Tests Report' },
//     ...panelInvoice


//     ]



const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

export default function Lab_Tests({}) {
  const {dispatcchUserEvents,billAmount,cartItems,TotalAmount,TotalDiscount,TotalPayable,TotalPayableAddDisc,TotalRfdAmount,TotalRfdPayable,TotalRfdPayableAddDisc,TotalRefundDiscountAmount,invoiceId,qrcodeScaner} = useContext(ProductContex);

  const classes = useStyles();
  const { width } = useWindowDimensions();

  const [CategoriesList, setCategoriesList] = React.useState([])
  const [DoctorNameList, setDoctorNameList] = React.useState([])
  const [ProductList, setProductList] = React.useState([])
  const [ProductListForCatFilter, setProductListForCatFilter] = React.useState([])

  const [SelectedProducts, setSelectedProducts] = React.useState([])
  const [remarks,setremarks]=React.useState("")
  const [specialDiscountPer,setSpecialDiscountPer]=React.useState(0)
  const [specialDiscountValue,setSpecialDiscountValue]=React.useState(0)
  // for refund special discount
  const [rfdSpecialDiscountPer,setrfdSpecialDiscountPer]=React.useState(0)
  const [rfdSpecialDiscountValue,setrfdSpecialDiscountValue]=React.useState(0)
  //for count data 
  const [count,setcount]=React.useState(1)
  // dialoge box
  const [DialogBoxOpen,setDialogBoxOpen]=React.useState(false)
  //client list 
  const [ClientList,setClientList]=React.useState([])
  const [open,setOpen]=React.useState(false)
  // for image View below line 3-feb-2024
  const [openImageView,setopenImageView]=React.useState(false)
  const [systemId,setsystemId]=React.useState()
  const [selectedReport,setSelectedReport]=React.useState()
  const [imageloading,setimageloading]=React.useState(false)
  // for qr code 
  const [scanResult,setscanResult]=React.useState(null)
  const [openQrCodeModal,setopenQrCodeModal]=React.useState(false)
  //6-june-2024 for client alert for admin pls select client first
  const [clientSelect,setClientSelect]=React.useState(false)
  // 21-june-2024 
  const [showQrScanner,setshowQrScanner]=React.useState(false)
  // 15-aug-2024
  const [loading,setLoading]=React.useState(false)
  // 19-aug-2024 

  const [searchValue,setsearchValue]=React.useState("")

  const [searchValueNotFound,setsearchValueNotFound]=React.useState(false)




 




  var tesTmg= "data:image/png;base64,";


  const history = useHistory();




  




  var from = new Date();

  useEffect(()=>{
console.log("yess")
if(localStorage.getItem('user_id')  ){
  // window.location.href="https://mpl-labs.pk"
  
 }
 else{
  history.push('/Login');  
 }

  },[cartItems])

  const [columns, setColumns] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Rate $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    {
      title: "Rfd", field: 'rfd', type: 'numeric' ,
      //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
      }
    },
    {
      title: 'Amount $', field: 'amount', type: 'numeric', editable: 'never', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
      }
    },
    {
      title: 'Disc %', field: 'disc', type: 'numeric', 
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
      }
    },
    {
      title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
      }
    },
    {
      title: 'Net Val $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    },
    {
      title: 'View', field: 'viewimage', 
      
    }
  ]);
  // cond lgae hai ager login pe if discount true hai to disc column edit ho sky
  const [columnsNewForCondiotion, setcolumnsNewForCondiotion] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Price $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    // {
    //   title: "Rfd", field: 'rfd', type: 'numeric' ,
    //   //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
    //    render: rowData => {
    //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
    //   }
    // },
    // {
    //   title: 'WholeSale_P $', field: 'amount', type: 'numeric', editable: 'never', 
    //   render: rowData => {
    //     return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
    //   }
    // },
    // {
    //   title: 'Disc %', field: 'disc', type: 'numeric', editable: 'never',
    //    render: rowData => {
    //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
    //   }
    // },
    // {
    //   title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
    //    render: rowData => {
    //     return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
    //   }
    // },
    {
      title: 'Net Value $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    },
    // {
    //   title: 'View', field: 'viewimage', 
      
    // }
  ]);
  const customCellStyle = (rowData) => {
    // Define your condition to change row color
    return {
      backgroundColor: rowData.age > 28 ? 'lightcoral' : 'lightgreen',
    };
  };
  const [data, setdata] = React.useState([
    {
    sn:1,
    code:"ABC",
    itemName:"abc",
    rate:20,
    qty:10,
    rfd:10,
    amount:100,
    discount:100,
    netvalue:100
  }])

  useEffect(()=>{
    // 12-aug-2024 and 14-aug-2024 qrcodeScaner lgae
    if(invoiceId!="" || qrcodeScaner!=""){
console.log("invoiceId or qrcodeScaner hai in invoice new me ")

    }
    else{
dispatcchUserEvents("ClearData")
    }

    if(qrcodeScaner!=""){
      fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_ClientSpricesCallsUp",

        {
          method:"POST",
          'Content-Type':"application/json",
          body: JSON.stringify({
            // branch_id:1,
            //  12-aug-2024
            client_id:localStorage.getItem('client_id'),
            user_type:localStorage.getItem("user_type")
    
          
            // user_id:localStorage.getItem('user_id')
          })
        }).then((res) => res.json()).then((response) => {
    console.log("check list of client spec price"+JSON.stringify(response))
    setProductList(response)
    setProductListForCatFilter(response)
    
        }).catch((e)=>{
          console.log(e)
        })
    }

    
    
    fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_CategoryCallingUp",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        // branch_id:parseInt(localStorage.getItem('branch_id')),

      
        user_type:localStorage.getItem("user_type")
      })
    }).then((res) => res.json()).then((response) => {
      console.log("check categories response")
      // console.log(response)
  
    setCategoriesList(response)
   

    })
    console.log(localStorage.getItem('user_id'))
    // 12-aug-2024
if(localStorage.getItem('if_admin')=="true" && invoiceId==""){
   
    fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_ClientsListCallingUp",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        // branch_id:parseInt(localStorage.getItem('branch_id')),
      
        user_id:localStorage.getItem('user_id')
      })
    }).then((res) => res.json()).then((response) => {

    var data = [];
    response.map((item) => {
      // console.log(item)
     
      var obj = {
        clientTitle: item.arr_cl_name,
        phone: item.arr_phone,
        city: item.arr_city,
        state: item.arr_state,
        zip: item.arr_zip,
        address: item.arr_address,

        select: (
          <Button
            variant="contained"
            color="primary"
            style={{
            backgroundColor:"#268ae1"

            }}
            className={classes.button}
            onClick={() => {
              setLoading(true)
              localStorage.setItem('client_id',item.arr_cl_id)
              localStorage.setItem('client_Title',item.arr_cl_name)
              // console.log("obj "+  client_id:item.arr_cl_id,)
              // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientSpecDiscPerCallsUp_api",
              fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_ClientSpricesCallsUp",

              {
                method:"POST",
                'Content-Type':"application/json",
                body: JSON.stringify({
                  // branch_id:1,
                  client_id:item.arr_cl_id,
                  user_type:localStorage.getItem("user_type")
                
                  // user_id:localStorage.getItem('user_id')
                })
              }).then((res) => res.json()).then((response) => {
console.log("check list of client spec price admin login"+JSON.stringify(response))

    setProductList(response)
    setProductListForCatFilter(response)
    setClientSelect(true)
    setLoading(false)

              })

        
          setOpen(false)
            }}
          >
            Select
          </Button>
        ),
      };

      data.push(obj);
    });
    setClientList(data);
  
  
    })
  }else {
    console.log("me cahala")
    console.log({
      // branch_id:1,
      //  12-aug-2024
      client_id:invoiceId==""? localStorage.getItem('user_id'):localStorage.getItem('client_id'),
      user_type:localStorage.getItem("user_type")
      // user_id:localStorage.getItem('user_id')
    })
    fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_ClientSpricesCallsUp",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        //  12-aug-2024
        client_id:invoiceId==""? localStorage.getItem('user_id'):localStorage.getItem('client_id'),
        user_type:localStorage.getItem("user_type")

      
        // user_id:localStorage.getItem('user_id')
      })
    }).then((res) => res.json()).then((response) => {
console.log("check list of client spec price 585"+JSON.stringify(response))
setProductList(response)
setProductListForCatFilter(response)

    }).catch((e)=>{
      console.log(e)
    })

  }
  
    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

    // {
    //   method:"POST",
    //   'Content-Type':"application/json",
    //   body: JSON.stringify({
    //     // branch_id:1,
    //     branch_id:parseInt(localStorage.getItem('branch_id')),

    //     category_id:0,
    //     user_id:localStorage.getItem('user_id')

    //   })
    // }).then((res) => res.json()).then((response) => {
    // console.log("check Products response "+JSON.stringify(response))  
    // setProductList(response)
 

    // }).catch((err)=>{
    //   console.log("err "+err)

    //  alert("Session Expired "+err)
    //  localStorage.clear()
    // })
    const handleTouchStart = (event) => {
      event.stopPropagation();
    };
  
    document.addEventListener('touchstart', handleTouchStart, { passive: true });
  
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };


  },[])
  useEffect(()=>{
    //for qr code
 const scanner =new Html5QrcodeScanner ('reader',{
  qrbox:{
    width:350,
    height:350
  },
  fps:5,
})
scanner.render(success,error)

function success(result){
  scanner.clear();
  setscanResult(result)
  setopenQrCodeModal(true)

}
function error(err){
  // scanner.clear();
  // console.log("error in scanner 531"+err)

}
    
      },[openQrCodeModal])
  const rerenderQrScanner=()=>{
    // const scanner =new Html5QrcodeScanner ('reader',{
    //   qrbox:{
    //     width:350,
    //     height:350
    //   },
    //   fps:5,
    // })
    // scanner.render(success,error)
    
    // function success(result){
    //   scanner.clear();
    //   setscanResult(result)
    //   setopenQrCodeModal(true)
    
    // }
    // function error(err){
    //   // scanner.clear();
    //   console.log("error in scanner 531"+err)
    
    // }
  }
 const autoAddItemIntoCart =()=>{
  const product=   ProductList.find(item => item.product_code == scanResult)
  console.log("product is ")
  // console.log(product)
  if(product){
  var obj1 =
  {
    sn:count,
    systemId: product.product_id,
    code: product.product_code,
    itemName: product.product_name,
    // rate:newValue.selling_price,
    // retail price k lye
    rate:product.price_offer_to_client,

    qty:1,
    rfd:0,
    rfdAmount:0,
    // amount:newValue.selling_price,
    // wholesaler price k lye

    // amount:product.price_wholesale,
    amount:product.price_offer_to_client,


    // disc:0,
    // discount:0,
    // special discount k lye

    // discount:product.discount_precentage==""?0:product.discount_precentage,
    discount:product.discount_precentage==""?0:product.discount_precentage,
    discountValue:product.discount_precentage==""?0:product.price_offer_to_client* product.discount_precentage/100,

    // netvalue:newValue.selling_price,
    // priceoffer to client es me dali hai

    netvalue:product.price_offer_to_client,


    buyingPrice:product.buying_price,
//                   viewimage:( 
//                   <RemoveRedEyeOutlinedIcon
//                   style={{color:"#5794ca",
  
//                 marginTop:-10}}
//                   onClick={() => {
//                     setopenImageView(true)
//                     setimageloading(true)
//                       var obj={
//   // branch_id:1,
//   system_id:newValue.system_id,

//   user_id:localStorage.getItem('user_id')
// }
// console.log("check obj"+JSON.stringify(obj))
//                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebPictureCallsProductSingle",

//                      {
//                        method:"POST",
//                        'Content-Type':"application/json",
//                        body: JSON.stringify(obj)
//                      }).then((res) => res.json()).then((response) => {
//                       if(response[0].request_status=="No Picture"){
//                         setopenImageView(false)
//                         alert("No Picture Found !")
//                     setimageloading(false)
     

//                         return
//                       }
//                        console.log("response "+JSON.stringify(response))
//                        setSelectedReport(`${tesTmg}`+response[0].picture_image)
//                     setimageloading(false)

        
        
//                      })
//                      .catch((err)=>{
//                       console.log(err)
//                     setimageloading(false)

//                      })
//                     // setsystemId(newValue.system_id)
     
    
//                   }}
//                   ></RemoveRedEyeOutlinedIcon>
    
//                   ),
     
  }
  
// console.log("check obj"+JSON.stringify(obj1))
dispatcchUserEvents("ADD_To_CART",{data:obj1,requestedQty:1})
setcount(count+1)
setopenQrCodeModal(false)
setscanResult("")
rerenderQrScanner()
  }
  else{
{clientSelect?
  alert("Product Not Found")
:
alert("Product Not Found ! Please Select a Client")

  
}
setopenQrCodeModal(false)

setscanResult("")

    rerenderQrScanner()
    // return
   
    // setopenQrCodeModal(false)

  }
 }
  const ModalClose=()=>{
    setopenQrCodeModal(false)
  }
  return (
    <>
      <Paper className={classes.root} style={{}}>
      {/* <h1 style={{color:"red"}}> hshshsahhdfahdsajhsdfagh</h1> */}
        <div style={{ padding: '1em'}}>
          {width<500?
          <div   style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={image} 
style={{height:80,width:width<500?300:400}}
 ></img>
 </div>
 :<></>}
          {/* <h3>Status</h3> */}
          {/* <h3 style={{color:"black",fontWeight:"bold",marginLeft:"1%"}} >
  
   Invoice Module
   
              </h3> */}
                <GridContainer>
                  {/*  12-aug-2024 */}
       {localStorage.getItem('if_admin')=="true" && invoiceId==""?
            <GridItem xs={12} sm={12} md={6} lg={3} style={{marginLeft:width<500?0:-10,marginTop:clientSelect?"2%":"0"}}>
              {/* 14-aug-202 new api jo change hui us k bad */}
              {/* {clientSelect?<></>: */}

              {localStorage.getItem('client_Title')!="" ?<></>:
              <h3 className='blink' style={{marginLeft:"2%",color:"red"}}>Please Select a Client</h3>

              }
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setOpen(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "13.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#268ae1"

           }}>
<GroupIcon style={{width:20,height:20,color:"white",marginRight:5}}></GroupIcon>
               Select Clients
           </Button>
           <div>
            {/* 14-aug-2024 new api jo change hui us k bad client select remove kr dya */}
           {/* {localStorage.getItem('client_Title')!="" && clientSelect? */}
           {localStorage.getItem('client_Title')!="" ?

           <p style={{fontWeight:"bold",marginLeft:"2%"}}>
            Client :
           {localStorage.getItem('client_Title')}</p>
            :<></>}
            </div>
            </GridItem>:<></>}
            {/* 19-aug-2024 add new changes */}
            <GridItem xs={12} sm={12} md={6} lg={3} style={{marginLeft:width<500?0:10}}>
     
   <TextField required id="" 
   style={{marginTop:width<500?0:10}}
  //  block
  variant="outlined" 
                  fontColor="red"
                  // style={{borderBottom:"2px solid red"}}
                  // 
                  inputProps={{ autoComplete: 'off' }}
                  fullWidth={true}
                  label="Search Product By itemCode "
                  value={searchValue}
                  InputProps={{
                    style: {
                     
                      color: 'black',  // Changes the font color of the input text
                    },
                  }}
                                  InputLabelProps={{ style: { color: '#767879' ,fontWeight:"bold"} }}

                  onChange={(e)=>{
                    setsearchValueNotFound(false)
                    setsearchValue(e.target.value)
                  }} 
                  // InputProps={{
                  //   classes: {
                  //     underline: "red",
                  //   },
                  // }}
                  // InputLabelProps={{
                  //   style: { color: 'red' },
                  // }}
                  />
           
            
              
</GridItem>
<GridItem xs={12} sm={12} md={6} lg={3} style={{marginLeft:width<500?0:10}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
      // "product_code":"MB-01"
      console.log(ProductList.length)
      if(ProductList.length>0){
        
     
    const product=  ProductList?.filter((item)=>{
    return  item.product_code.toLowerCase()==searchValue.toLowerCase()
    //  ||
      // item.product_name.toLowerCase()==searchValue.toLowerCase()
      })
      if(product.length>0)
        {
      console.log(product)
      var obj1 =
      {
        sn:count,
        systemId: product[0].product_id,
        code: product[0].product_code,
        itemName: product[0].product_name,
        // rate:newValue.selling_price,
        // retail is price 
        
        rate:product[0].price_offer_to_client,

        qty:1,
        rfd:0,
        rfdAmount:0,
        // amount:newValue.selling_price,
        // wholesaler price k lye

        amount:product[0].price_offer_to_client,

        // disc:0,
        // discount:0,
        // special discount k lye

        discount:product[0].discount_precentage==""?0:product[0].discount_precentage,
        discountValue:product[0].discount_precentage==""?0:product[0].price_offer_to_client* product[0].discount_precentage/100,

        // netvalue:newValue.selling_price,
        // priceoffer to client es me dali hai

        netvalue:product[0].price_offer_to_client,


        buyingPrice:product[0].buying_price,
//                   viewimage:( 
//                   <RemoveRedEyeOutlinedIcon
//                   style={{color:"#5794ca",
      
//                 marginTop:-10}}
//                   onClick={() => {
//                     setopenImageView(true)
//                     setimageloading(true)
//                       var obj={
//   // branch_id:1,
//   system_id:newValue.system_id,

//   user_id:localStorage.getItem('user_id')
// }
// console.log("check obj"+JSON.stringify(obj))
//                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebPictureCallsProductSingle",

//                      {
//                        method:"POST",
//                        'Content-Type':"application/json",
//                        body: JSON.stringify(obj)
//                      }).then((res) => res.json()).then((response) => {
//                       if(response[0].request_status=="No Picture"){
//                         setopenImageView(false)
//                         alert("No Picture Found !")
//                     setimageloading(false)
         

//                         return
//                       }
//                        console.log("response "+JSON.stringify(response))
//                        setSelectedReport(`${tesTmg}`+response[0].picture_image)
//                     setimageloading(false)

            
            
//                      })
//                      .catch((err)=>{
//                       console.log(err)
//                     setimageloading(false)

//                      })
//                     // setsystemId(newValue.system_id)
         
        
//                   }}
//                   ></RemoveRedEyeOutlinedIcon>
        
//                   ),
         
      }
      
// console.log("check obj"+JSON.stringify(obj1))
dispatcchUserEvents("ADD_To_CART",{data:obj1,requestedQty:1})
setcount(count+1)
setsearchValue("")
    }
    else{
      // alert("Product Not Found!")
      setsearchValueNotFound(true)
    }

    }
    else{
      alert("Product List is Empty Please Select Client Again !")
    }
        
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "13.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#268ae1",
            marginTop:width<970?10:10

           }}>
<Search style={{width:20,height:20,color:"white",}}></Search>
               {/* Show Scanner  */}
             Search
           </Button>
           {searchValueNotFound && 
            <p style={{color:"red",fontWeight:"bold"}}>Product Not Found !</p>
           }
</GridItem>
            <GridItem xs={12} sm={12} md={6} lg={3} style={{marginLeft:width<500?0:-10}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        if(showQrScanner){
          setshowQrScanner(false)
        }else{
        setshowQrScanner(true)
        }
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "13.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#268ae1",
            marginTop:width<970?10:10

           }}>
<CropFreeIcon style={{width:20,height:20,color:"white",}}></CropFreeIcon>
               {/* Show Scanner  */}
               {showQrScanner?"Close Scanner":"Open Scanner"} 
           </Button>
           
              {/* <h4>Qr / Barcode  scanner </h4> */}
              {/* <div id="reader"></div> */}
              {scanResult?<div>success:{scanResult}</div>:
              <div style={{display:showQrScanner?"":"none"}} id="reader" ></div>
              }
              
</GridItem>
            
            </GridContainer>
           
          <GridContainer style={{marginTop:width<500&&localStorage.getItem('client_Title')?"5%":width<500?"4%":"1%"}}>
            <GridItem xs={12} sm={12} md={4} style={{marginLeft:width<500?"0%":"1%"}}>
              
         
                 <Autocomplete
                  id="Ref_By_Doctor"

                  options={CategoriesList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.cats_title+ ""}
                  style={{
                    // width: "30em",
                    marginRight: '1em'
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={(event, newValue) => {
                    // setProductList([])
                    if (newValue != null) {

                      // console.log(newValue.cats_id)
                      const filteredProduct = ProductListForCatFilter?.filter(list =>  list.category_id== newValue.cats_id);
                      // console.log(filteredProduct)

                    setProductList(filteredProduct)

                      // yhan pe aik aur copy bnanai hai product list ki us pe map lga k cat wise filter krna hia aur us ko productlist me dalna hai
                    }
                    else{
                      setProductList(ProductListForCatFilter)
                    }

 
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Categories"
                      variant="outlined"
                      style={{
                        // width: "30em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                        readOnly:width<500? true:false, // make the input read-only

           
                      }}
                     
                    />
                  )}
                />
           
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:width<500?"4%":width<960?"2%":"0%"}}>
           
            <Autocomplete
                  id="Ref_By_Doctor"

                  options={ProductList}
                  classes={{
                    // option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.product_name + ""}
                  style={{
                    // width: "30em",
                    marginRight: '1em',
                    marginLeft:width<500?"0em ":width<960?"4px": '0em',
                    

                    
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={async(event, newValue) => {
                    // console.log("check cartItem"+cartItems)

                    if (newValue != null) {

                      // setRef_By_Dr(newValue.DoctorID)
                      // console.log("Selected Product"+newValue.system_id)
                      // setSelectedProducts(newValue)
                     
                      var obj1 =
                  {
                    sn:count,
                    systemId: newValue.product_id,
                    code: newValue.product_code,
                    itemName: newValue.product_name,
                    // rate:newValue.selling_price,
                    // retail is price 
                    
                    rate:newValue.price_offer_to_client,

                    qty:1,
                    rfd:0,
                    rfdAmount:0,
                    // amount:newValue.selling_price,
                    // wholesaler price k lye

                    amount:newValue.price_offer_to_client,

                    // disc:0,
                    // discount:0,
                    // special discount k lye

                    discount:newValue.discount_precentage==""?0:newValue.discount_precentage,
                    discountValue:newValue.discount_precentage==""?0:newValue.price_offer_to_client* newValue.discount_precentage/100,

                    // netvalue:newValue.selling_price,
                    // priceoffer to client es me dali hai

                    netvalue:newValue.price_offer_to_client,


                    buyingPrice:newValue.buying_price,
  //                   viewimage:( 
  //                   <RemoveRedEyeOutlinedIcon
  //                   style={{color:"#5794ca",
                  
  //                 marginTop:-10}}
  //                   onClick={() => {
  //                     setopenImageView(true)
  //                     setimageloading(true)
  //                       var obj={
  //   // branch_id:1,
  //   system_id:newValue.system_id,
  
  //   user_id:localStorage.getItem('user_id')
  // }
  // console.log("check obj"+JSON.stringify(obj))
  //                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebPictureCallsProductSingle",

  //                      {
  //                        method:"POST",
  //                        'Content-Type':"application/json",
  //                        body: JSON.stringify(obj)
  //                      }).then((res) => res.json()).then((response) => {
  //                       if(response[0].request_status=="No Picture"){
  //                         setopenImageView(false)
  //                         alert("No Picture Found !")
  //                     setimageloading(false)
                     

  //                         return
  //                       }
  //                        console.log("response "+JSON.stringify(response))
  //                        setSelectedReport(`${tesTmg}`+response[0].picture_image)
  //                     setimageloading(false)

                        
                        
  //                      })
  //                      .catch((err)=>{
  //                       console.log(err)
  //                     setimageloading(false)

  //                      })
  //                     // setsystemId(newValue.system_id)
                     
                    
  //                   }}
  //                   ></RemoveRedEyeOutlinedIcon>
                    
  //                   ),
                     
                  }
                  
// console.log("check obj"+JSON.stringify(obj1))
dispatcchUserEvents("ADD_To_CART",{data:obj1,requestedQty:1})
setcount(count+1)


                      
                
                    } else {
                
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Products"
                      variant="outlined"
                      
                      style={{
                        // width: "30em"
                        borderColor:"red"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" ,// disable autocomplete and autofill
                           readOnly:width<500? true:false, // make the input read-only
           
                      }}
                    />
                  )}
                />
            
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={3} style={{marginTop:width<960?"2%":"0%"}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setOpen(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "13.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
<GroupIcon style={{width:20,height:20,color:"white",marginRight:5}}></GroupIcon>
               Select Clients
           </Button>
           {localStorage.getItem('client_Title')?
           <p style={{fontWeight:"bold",marginLeft:"2%"}}>
            Client :
           {localStorage.getItem('client_Title')}</p>
            :<></>}
            </GridItem> */}
            <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop:width<500?"4%":"2%",height:"400px", overflowY: "auto",borderBottom:"1px solid #ccc"}}>
              {/* {width>500? */}
              {/* <MaterialTable
                // title="Selected Products"
                title={<span style={{ color: '#818285',fontWeight:"bold" }}>Selected Products</span>}
                // columns={columns}
                columns={localStorage.getItem('If_allow_discount')=="true"?columns:columnsNewForCondiotion}

                
                data={cartItems}
                style={{
                  marginLeft:width<960?0: 10,
                  border:" 2px solid #268ae1",
                  
                }}
                
                options={{
                  // paging: false,
                  headerStyle: {
                    backgroundColor: "#268ae1",
                    color: "#FFF",
                    // fontSize: "17px",
                    // textAlign: "center",
                    fontWeight: "bold",
                    // minHeight:"50em"
                  },
                
                  rowStyle: rowData => {
                    // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
                    //   return {};
                    // }
                    // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
                    return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
                  };
                  }
                }}
// minBodyHeight={"25em"}
                maxBodyHeight={"50em"}
            
                editable={{
                // YE FILHAL SKIP KR RHA HUN EDIT DENA HAI VALUE CORRECT KR K 6-JUNE-2024 KO COMMENTS KIYA

                  onRowUpdate: ( newData, oldData) =>
                    new Promise((resolve, reject) => {
                      // setTimeout(async () => {
                        // console.log("old data"+JSON.stringify(oldData))
                        // console.log("new data"+JSON.stringify(newData))
                        const index = oldData.systemId;
                        if(oldData.qty==0&&oldData.rfd>0){
                        // console.log("check oper rfd"+newData.rfd+"-"+"qty"+newData.qty)

                          newData.rfd=0
                        }
                        if(newData.rfd>0 &&newData.qty!=0){
                        // console.log("check niche"+newData.rfd+"-"+"qty"+newData.qty)

                        newData.qty=0
                        // console.log("check rfd"+newData.rfd+"-"+"qty"+newData.qty)
                      }
                     
                        // console.log("index is "+index)
                        const dataUpdate = [...cartItems];
                        // console.log("New data is "+JSON.stringify(newData.rfd))
                        resolve()
            dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:newData.qty,rfd:newData.rfd,updatedAmount:(newData.qty*newData.rate).toFixed(2),disc:newData.discount,updatedRfdAmount:(newData.rfd*newData.rate).toFixed(2)})

            dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:newData.qty,updatedAmount:(newData.qty*newData.rate).toFixed(2),rfd:newData.rfd,updatedRfdAmount:(newData.rfd*newData.rate).toFixed(2)})
           // 19-jan-2024 add kiya tha ku k editable se phly agr special disc apply kiya tha to dobara kr den 
           setSpecialDiscountPer(0)
           setSpecialDiscountValue(0)
            resolve()
            this.forceUpdate();
            
                        resolve()
                     




                        resolve();
                    
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        // const index = oldData.systemId;
                        let index = cartItems.findIndex(x => x.systemId == oldData.systemId);

                        // console.log("check delete data"+JSON.stringify(oldData))
                        dispatcchUserEvents("REMOVE_FROM_CART",{data:oldData,deductedAmount:oldData.qty*oldData.netvalue,index})
                        setSpecialDiscountPer(0)
                        setSpecialDiscountValue(0)
                        
                        resolve();

                      }, 1000)
                    }),
                }}
              /> */}
            {/* // :<> */}
               {/* header start */}

            <div style={{width:"100%",backgroundColor:"#268ae1",border:"1px solid #268ae1",display:"flex",flexDirection:"row",color:"white",paddingTop:width<500?"4%":"0.5%",paddingBottom:width<500?"4%":"0.5%",borderTopRightRadius:5,borderTopLeftRadius:5}}>
             <div style={{width:"8%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
           <p style={{fontWeight:"bold",}}>  S/N</p>
           {/* <p style={{fontWeight:"bold",color:"#77787a"}}>  1</p> */}


           </div>
           <div style={{width:"23%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
           <p style={{fontWeight:"bold",}}>  Name</p>
           {/* <p style={{fontWeight:"bold",color:"#77787a"}}>  BLUE DREAM THC O+P CART</p> */}


           </div>
           <div style={{width:"20%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           <p style={{fontWeight:"bold",}}>    Price $</p>

           {/* <p style={{fontWeight:"bold",color:"#77787a"}}>    100,</p> */}



           </div>
           <div style={{width:"23%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           <p style={{fontWeight:"bold"}}>    Qty</p>
           {/* <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
           <button style={{width:50,backgroundColor:"#cccc",border:"2px solid #268ae1",borderTopLeftRadius:5,borderBottomLeftRadius:5}}>-</button>
           <button style={{width:50,backgroundColor:"white",borderBottom:"2px solid #268ae1",borderTop:"2px solid #268ae1",borderLeft:"2px solid #268ae1",borderRight:"2px solid #268ae1"}}>1</button>
           <button style={{width:50,backgroundColor:"#cccc",border:"2px solid #268ae1",borderTopRightRadius:5,borderBottomRightRadius:5}}>+</button>

           </div> */}



           </div>
           <div style={{width:"20%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           <p style={{fontWeight:"bold",}}>    Net Value $</p>
           {/* <p style={{fontWeight:"bold",color:"#77787a"}}>    100</p> */}


           </div>
           <div style={{width:"6%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

<p style={{fontWeight:"bold",}}> </p>
{/* <p style={{fontWeight:"bold",color:"#77787a"}}>    100</p> */}


</div>
               </div>
               {/* header end */}
             
{/* row start */}
{loading?  <center>
            <ReactLoading type={'spinningBubbles'} color={'#196c90'} height={'10em'} width={'10em'} />
          </center>:

<div style={{width:"100%",height:"100%",backgroundColor:"white",border:"1px solid #ccc",display:cartItems.length==0 ?"flex":"",justifyContent:cartItems.length==0 ?"center":""}}>
{cartItems?.map((item,index)=>{
  return(
  <div style={{marginTop:2,width:"100%",backgroundColor:"white",borderTop:"1px solid #ccc",borderBottom:"1px solid #ccc",display:"flex",flexDirection:"row",color:"white"}}>
             <div style={{width:"8%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>  S/N</p> */}
           <p style={{fontWeight:"bold",color:"#268ae1"}}>  {index+1}</p>


           </div>
           <div style={{width:"23%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}} >
           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>  ItemName</p> */}
           <p style={{fontWeight:"bold",color:"#77787a",fontSize:width<500?10:15}}>  {item.itemName}</p>


           </div>
           <div style={{width:"20%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Price</p> */}

           <p style={{fontWeight:"bold",color:"#77787a"}}> $  {item.rate.toFixed(2)} </p>



           </div>
           <div style={{width:"23%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Qty</p> */}
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
           <button style={{width:width<500?25:50,backgroundColor:"#268ae1",borderLeft:"2px solid #77787a",borderTop:"2px solid #77787a",borderBottom:"2px solid #77787a",borderRight:"2px solid #268ae1",borderTopLeftRadius:5,borderBottomLeftRadius:5,color:"white"}} onClick={()=>{
            let index = cartItems.findIndex(x => x.systemId == item.systemId);
           // dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
           console.log("index")
           
           console.log(index)
           if(item.qty<=1){
            alert("Product qty must b greater than zero ")
           }
           else{
           
           dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty-1,updatedAmount:((item.qty-1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
           }
          }}>-</button>
           {/* <p style={{fontWeight:"bold",color:"#77787a"}}>    1</p> */}
           <button style={{width:width<500?25:50,backgroundColor:"white",borderBottom:"2px solid #77787a",borderTop:"2px solid #77787a",borderLeft:"2px solid #77787a",borderRight:"2px solid #77787a"}}
           >{item.qty}</button>
           <button style={{width:width<500?25:50,backgroundColor:"#268ae1",border:"2px solid #77787a",borderLeft:"2px solid #268ae1",borderTopRightRadius:5,borderBottomRightRadius:5,color:"white"}} onClick={()=>{
 let index = cartItems.findIndex(x => x.systemId == item.systemId);
// dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
console.log("index")

console.log(index)

dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
           }}>+</button>

           </div>



           </div>
           <div style={{width:"20%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}
           <p style={{fontWeight:"bold",color:"#77787a"}}>$ {item.netvalue.toFixed(2)} </p>


           </div>
           <div style={{width:"6%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}
{/* <p style={{fontWeight:"bold",color:"#77787a"}}> {item.netvalue}</p> */}
< VisibilityIcon style={{fontSize:width<500?"20px":"24px",paddingBottom:width<500?5:0}}  className='iconcss' onClick={()=>{
  // alert("chak de phatty"+JSON.stringify(item))
                      setopenImageView(true)
                      setimageloading(true)
                        var obj={
    // branch_id:1,
    system_id:item.systemId,
  
    user_id:localStorage.getItem('user_id')
  }
  console.log("check obj"+JSON.stringify(obj))
                       fetch("https://4dserver.globaldistrous.com./4DACTION/WebPictureCallsProductSingle",

                       {
                         method:"POST",
                         'Content-Type':"application/json",
                         body: JSON.stringify(obj)
                       }).then((res) => res.json()).then((response) => {
                        if(response[0].request_status=="No Picture"){
                          setopenImageView(false)
                          alert("No Picture Found !")
                      setimageloading(false)
                     

                          return
                        }
                         console.log("response "+JSON.stringify(response))
                         setSelectedReport(`${tesTmg}`+response[0].picture_image)
                      setimageloading(false)

                        
                        
                       })
                       .catch((err)=>{
                        console.log(err)
                      setimageloading(false)
                       }
                       )}
  
}></ VisibilityIcon>
<DeleteIcon style={{fontSize:width<500?"22px":"24px",paddingTop:width<500?5:0}} className='iconcss'  onClick={()=>{
  // alert("chak de phatty"+JSON.stringify(item))
  let index = cartItems.findIndex(x => x.systemId == item.systemId);

                        // console.log("check delete data"+JSON.stringify(oldData))
                        dispatcchUserEvents("REMOVE_FROM_CART",{data:item,deductedAmount:item.qty*item.netvalue,index})
                        setSpecialDiscountPer(0)
                        setSpecialDiscountValue(0)
}}></DeleteIcon>


</div>
               </div>
  )
})}
{cartItems.length==0 ?<ShoppingCartIcon style={{color:"#268ae1",height:"50%",width:"50%",alignSelf:"center"}} />:<></>} 
</div>
}
             
{/* row end*/}




             
             
              
         
            {/* //   </>} */}
            </GridItem>
            {/* <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Just Booked</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'red', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Only Phlebotomy Done</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'purple', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Lying Pending Results</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} >
              <div style={{
                backgroundColor: 'blue', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Done But not Signed</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} >
              <div style={{
                backgroundColor: 'green', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Ready</p>
            </GridItem> */}

          </GridContainer>
          <GridContainer >
                     
                     <GridItem sm={12} md={12}lg={4} >
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:width<960?0:25
     }}>
       <b>Remarks</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '5em',
           marginLeft: 1
         }}
         value={remarks}
         onChange={(e) => {
           setremarks(e.target.value)
         }}
         placeholder="Transaction Remarks"
       /></span>
                     </GridItem>
                     <GridItem sm={12} md={12} lg={4} style={{marginTop:"2%"}}>
                   
                     {/* <TextField
            required
            id="outlined-required"
            label="Refund Total $"
            style={{
              borderRadius: 5,
              // width:"22%",
              marginTop:width<500?10:10,

              width:width<500?"48%":"36.8%",
              // marginRight:"4%"
              marginRight:width<500?"4%":"3.2%"
             
            }}
            // value={billAmount}
            value={TotalRfdAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
                     <TextField
            // required
            id="outlined-required"
            label="Refund Discount $"
            style={{
              borderRadius: 5,
              // width:"22%",
              width:width<500?"48%":"36.8%",
              marginTop:width<500?10:10,

              marginRight:width<500?"0%":"3.2%"
              
            }}
            // value={billAmount}
            value={TotalRefundDiscountAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
           <TextField
          // required
          id="outlined-required"
          label="Refund Spec Disc %"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",
            marginTop:width<500?10:10,

            // marginRight:"2%"
            marginRight:width<500?"4%":"3.2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountPer}

          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
          }}
          onChange={(e) => {
              
            if(e.target.value<100){
              console.log("rfdSpecialDiscountPer is"+e.target.value)
              let totalPer =((e.target.value/100)*TotalRfdAmount)
              console.log("totalPer is"+totalPer)
              
             
              let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
                setrfdSpecialDiscountPer(0)
                setrfdSpecialDiscountValue(0)
              }
            else{
      dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount,onChangeValue:e.target.value})
      setrfdSpecialDiscountValue(totalPer.toFixed(2))
     setrfdSpecialDiscountPer(e.target.value)

            }
            
            // setrfdSpecialDiscountPer(e.target.value)
          }else{
            alert("Special Disc must be less than 100")
          }
       
    
          }}
          
        /> */}
         {/* {rfdSpecialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                 
                      if(TotalRfdAmount){

          
          
          console.log("rfdSpecialDiscountPer is"+rfdSpecialDiscountPer)
          let totalPer =((rfdSpecialDiscountPer/100)*TotalRfdAmount)
          console.log("totalPer is"+totalPer)
          
         
          let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount})
  setrfdSpecialDiscountValue(totalPer.toFixed(2))
 setrfdSpecialDiscountPer(rfdSpecialDiscountPer)
        }
                        
                        }
                         else{
                          alert("no refund Products show ")
                         }
                 






                  }
                }>Apply</Button>} */}
              {/* <TextField
          // required   
          id="outlined-required"
          label="Refund Spec Disc $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",
            marginTop:width<500?10:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountValue}

          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
          }}
          onChange={(e) => {
              
            // if(e.target.value<100){
              let totalPercentage =((e.target.value*100)/TotalRfdAmount)

              let totalPer =((totalPercentage/100)*TotalRfdAmount)
              console.log("totalPer is"+totalPer)
              
             
              let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
                setrfdSpecialDiscountPer(0)
                setrfdSpecialDiscountValue(0)
              }
            else{
      dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount,onChangeValue:e.target.value})
      setrfdSpecialDiscountValue(e.target.value)
     setrfdSpecialDiscountPer(totalPercentage.toFixed(2))

            }
            
            
            // setrfdSpecialDiscountValue(e.target.value)
          // }else{
          //   alert("Special Disc must be less than 100")
          // }
       
    
          }}
          
        />
                    
         
                     <TextField
            // required
            id="outlined-required"
            label="Refund Net Val $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
              marginRight:width<500?"0%":"3.2%",

              marginTop:width<500?10:0,

              marginTop:10,
              // marginLeft:5
            }}
            // value={TotalRfdPayable}
            value={TotalRfdPayableAddDisc}

           
            type="number"
           
            variant="outlined"
             InputProps={{
            readOnly: true,
          }}
          /> */}
             {/* next four */}
                     {/* <TextField
            // required
            id="outlined-required"
            label="Amount $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,
              // marginLeft:5
            }}
            value={TotalAmount}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
       
           <TextField
          // required
          id="outlined-required"
          label="Discount $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            marginTop:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.1%",

            // marginTop:6,
            // marginLeft:5
          }}
          value={TotalDiscount}
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount per %"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountPer}
            type="number"
           
            variant="outlined"
            onChange={(e) => {
              
              if(e.target.value<100){

              
              // setSpecialDiscountPer(e.target.value)
              let totalVal =(TotalAmount-e.target.value)
              let totalPer =((e.target.value/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
              setSpecialDiscountValue(totalPer.toFixed(2))
              console.log("special discount amount is"+totalPer)
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              console.log("check TotalDiscount+totalPerAmount"+(TotalDiscount+totalPerAmount))
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount,onChangeValue:e.target.value})

              // }
              }
     setSpecialDiscountPer(e.target.value)
            }
            }else{
              alert("Special Disc must be less than 100")
            }
         
      //         if(netValue>0){
      //    var total= ((discount)/(100))*netValue
      //    setnetValue(netValue-total)
      //     }
            }}
          />  
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount Value $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountValue}
            type="number"
           
            variant="outlined"
            onChange={(e) => {
              // 
              let totalPercentage =((e.target.value*100)/TotalAmount)
              console.log("total percent is "+totalPer)
              // let totalVal =(TotalAmount-e.target.value)
              let totalPer =((totalPercentage/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
             
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total Amount")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountPer:totalPerAmount,onChangeValue:totalPercentage})

              // }
              }
            setSpecialDiscountPer(totalPercentage.toFixed(2))

    
            }
            setSpecialDiscountValue(e.target.value)



             
              console.log("check "+e.target.value)
              
         
            }}
          />  
         
          
          
          <TextField
          // required
          id="outlined-required"
          label="Payable $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

            marginTop:10,

            // marginTop:6,
            // marginLeft:5
          }}
          // value={TotalPayable}
          value={TotalPayableAddDisc}
          
          
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        /> */}
                   
           {/* </GridItem> */}
                     </GridItem>
                     <GridItem sm={12} md={12} lg={4} style={{marginTop:"2%"}}>
                     {/* <GridItem sm={6} md={6} lg={12}> */}
                     {/* <TextField
            required
            id="outlined-required"
            label="Refund Total"
            style={{
              borderRadius: 5,
              // width:"22%",
              marginTop:width<500?10:0,

              width:width<500?"48%":"22%",
              // marginRight:"4%"
              marginRight:width<500?"4%":"2%"
             
            }}
            // value={billAmount}
            value={TotalRfdAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
                     <TextField
            required
            id="outlined-required"
            label="Refund Discount"
            style={{
              borderRadius: 5,
              // width:"22%",
              width:width<500?"48%":"22%",
              marginTop:width<500?10:0,

              marginRight:width<500?"0%":"2%"
              
            }}
            // value={billAmount}
            value={TotalRefundDiscountAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
           <TextField
          required
          id="outlined-required"
          label="Refund Spec Disc"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"22%",
            marginTop:width<500?10:0,

            // marginRight:"2%"
            marginRight:width<500?"4%":"2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountPer}

          type="number"
         
          variant="outlined"
          onChange={(e) => {
              
            if(e.target.value<100){

            
            setrfdSpecialDiscountPer(e.target.value)
          }else{
            alert("Special Disc must be less than 100")
          }
       
    //         if(netValue>0){
    //    var total= ((discount)/(100))*netValue
    //    setnetValue(netValue-total)
    //     }
          }}
          
        />
         {rfdSpecialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                    // if(cartItems>0){
                      if(TotalRfdAmount){

          
          // let totalVal =(TotalAmount-specialDiscountPer)
          console.log("rfdSpecialDiscountPer is"+rfdSpecialDiscountPer)
          let totalPer =((rfdSpecialDiscountPer/100)*TotalRfdAmount)
          console.log("totalPer is"+totalPer)
          
          // total kitna special discount hua ye save kiya hai send krna hai 
          // setSpecialDiscountValue(totalPer)
          // console.log("special discount amount is"+totalPer)
          let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount})
  setrfdSpecialDiscountValue(totalPer.toFixed(2))
 setrfdSpecialDiscountPer(rfdSpecialDiscountPer)
        }
                        
                        }
                         else{
                          alert("no refund Products show ")
                         }
                    // }
                    // else{
                    //   alert("Please select Products ")
                    //   // setdiscount(0)
                    // }






                  }
                }>Apply</Button>}
          
                    
         
                     <TextField
            required
            id="outlined-required"
            label="Refund Net Value"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"22%",

              // marginRight:"2%",
              marginRight:width<500?"0%":"2%",

              marginTop:width<500?10:0,

              // marginTop:6,
              // marginLeft:5
            }}
            value={TotalRfdPayable}
            type="number"
           
            variant="outlined"
             InputProps={{
            readOnly: true,
          }}
          /> */}
             {/* next four */}
                     <TextField
            // required
            id="outlined-required"
            label="Amount $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,
              // marginLeft:5
            }}
            value={TotalAmount}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
          {/* 15-aug-2024 filhal comment kr rha  */}
       {/* {TotalDiscount?
       
           <TextField
          // required
          id="outlined-required"
          label="Discount $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            marginTop:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.1%",

            // marginTop:6,
            // marginLeft:5
          }}
          value={TotalDiscount}
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
        :<></>} */}
            {/* <TextField
            // required
            id="outlined-required"
            label="Spec Discount per %"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountPer}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
            }}
            onChange={(e) => {
              
              if(e.target.value<100){

              
              // setSpecialDiscountPer(e.target.value)
              let totalVal =(TotalAmount-e.target.value)
              let totalPer =((e.target.value/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
              console.log("special discount amount is"+totalPer)
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              console.log("check TotalDiscount+totalPerAmount"+(TotalDiscount+totalPerAmount))
              if(TotalDiscount+totalPerAmount>TotalAmount){
                // alert("do not apply more than Total")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount,onChangeValue:e.target.value})

              // }
              }
              setSpecialDiscountValue(totalPer.toFixed(2))

     setSpecialDiscountPer(e.target.value)
            }
            }else{
              alert("Special Disc must be less than 100")
            }
         
      //         if(netValue>0){
      //    var total= ((discount)/(100))*netValue
      //    setnetValue(netValue-total)
      //     }
            }}
          />   */}
            {/* <TextField
            // required
            id="outlined-required"
            label="Spec Discount Val $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountValue}
            type="number"
            InputProps={{
              readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
            }}
            variant="outlined"
            onChange={(e) => {
              // 
              let totalPercentage =((e.target.value*100)/TotalAmount)
              console.log("total percent is "+totalPer)
              // let totalVal =(TotalAmount-e.target.value)
              let totalPer =((totalPercentage/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
             
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total Amount")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountPer:totalPerAmount,onChangeValue:totalPercentage})

              // }
              }
            setSpecialDiscountPer(totalPercentage.toFixed(2))
            setSpecialDiscountValue(e.target.value)

    
            }



             
              console.log("check "+e.target.value)
              
         
            }}
          />   */}
            {/* {specialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                    // if(cartItems>0){
                      if(TotalAmount){

          let totalVal =(TotalAmount-specialDiscountPer)
          let totalPer =((specialDiscountPer/100)*TotalAmount)
          // total kitna special discount hua ye save kiya hai send krna hai 
          setSpecialDiscountValue(totalPer.toFixed(2))
          console.log("special discount amount is"+totalPer)
          let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount})
 setSpecialDiscountPer(specialDiscountPer)
        }
                        
                        }
                         else{
                          alert("Please Select Products ")
                         }
                  }
                }>Apply</Button>} */}
          
          
          <TextField
          // required
          id="outlined-required"
          label="Payable $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

            marginTop:10,

            // marginTop:6,
            // marginLeft:5
          }}
          // value={TotalPayable}
          value={TotalPayableAddDisc}
          
          
          type="number"
         
          variant="outlined"
          
          InputProps={{
            readOnly: true,
          }}
        />
                   
           {/* </GridItem> */}
                     </GridItem>
                     </GridContainer>
                     <GridContainer style={{marginTop:"2%"}}>
                     <GridItem sm={12} md={12} lg={6}></GridItem>
                     <GridItem sm={12} md={12} lg={6} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
            //  SeveRecordHandler()
            // alert("check data "+remarks+from.toISOString())
            // window.location.reload();
dispatcchUserEvents("ClearData")
            
history.push("/")
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "12.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#268ae1"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
             Cancel Order
           </Button>
           <Button
           variant="contained"
           color="primary"
           onClick={() => {
            // alert("Sorry ! In Progress")
            // console.log(cartItems)
       
        
           
                if(cartItems.length!=0){
              // var ArrEntrySequence=[]
              var ArrSequenceNo=[]
              var ArrItemSystemID=[]
              var ArrItemCode=[]
              var ArrItemName=[]
              var ArrItemQty=[]
              var ArrItemRate=[]
              var ArrItemAmount=[]
              // var ArrItemUnitCost=[]
              var ArrDiscountPercent=[]
              var ArrDiscountValue=[]
              // var ArrRefundQty=[]
              // var ArrRefundValue=[]

              for(let i=cartItems.length-1;i>=0;i--){
              // for(let i=0;i<cartItems.length;i++){

                        // console.log("check array result"+ JSON.stringify(cartItems[i]))
                        // ArrEntrySequence.push(cartItems[i].sn)
                        ArrSequenceNo.push(i+1)
                        ArrItemSystemID.push(cartItems[i].systemId)
                        ArrItemCode.push(cartItems[i].code)
                        ArrItemName.push(cartItems[i].itemName)
                        ArrItemQty.push(cartItems[i].qty)
                        ArrItemRate.push(cartItems[i].rate)
                        ArrItemAmount.push(cartItems[i].netvalue)
                        // yhan pe net value ki jga buyingPrice aaega 
                        // ArrItemUnitCost.push(parseFloat((cartItems[i].netvalue)))
                        ArrDiscountPercent.push(cartItems[i].discount)
                        ArrDiscountValue.push(parseFloat(cartItems[i].discountValue))
                        // ArrRefundQty.push(cartItems[i].rfd)
                        // ArrRefundValue.push(parseFloat((cartItems[i].rfdAmount)))


                        
              }
             
            let data={
              invoice_no:invoiceId!=""&& qrcodeScaner==""?invoiceId:"",
              // branch_id:parseInt(localStorage.getItem('branch_id')),
              // invoice_date:from.toISOString(),
              invoice_remarks:remarks,
              user_id:localStorage.getItem('user_id'),
              user_name:localStorage.getItem('user_name'),
              user_type:localStorage.getItem('user_type'),

              client_id:localStorage.getItem('client_id'),
              client_title:localStorage.getItem('client_Title'),
              inv_amount:parseFloat(TotalAmount),
              inv_discount:TotalDiscount,
              // inv_discount_special_percent:parseFloat(specialDiscountPer)?parseFloat(specialDiscountPer):0,
              // inv_discount_special_value:parseFloat(specialDiscountValue)?parseFloat(specialDiscountValue):0,
              
              // invoice_net_value:parseFloat(TotalPayableAddDisc),

              // refund_amount:parseFloat(TotalRfdAmount),
              // refund_line_discount:TotalRefundDiscountAmount,
              // refund_special_discount_percent:parseFloat(rfdSpecialDiscountPer),
              // refund_special_discount_value:parseFloat(rfdSpecialDiscountValue),
              // refund_net_value:parseFloat(TotalRfdPayable),
              // ArrEntrySequence:ArrEntrySequence,
              ArrSequenceNo:ArrSequenceNo,
              ArrItemSystemID:ArrItemSystemID,
              ArrItemCode:ArrItemCode,
              ArrItemName:ArrItemName,
              ArrItemQty:ArrItemQty,
              ArrItemRate:ArrItemRate,
              ArrItemAmount:ArrItemAmount,
              // ArrItemUnitCost:ArrItemUnitCost,
              ArrItemDisc_perc:ArrDiscountPercent,
              ArrItemDisc_value:ArrDiscountValue,
              // ArrRefundQty:ArrRefundQty,
              // ArrRefundValue:ArrRefundValue

            }
            console.log(JSON.stringify(data))
//             // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceSavingUpdating",
            fetch("https://4dserver.globaldistrous.com./4DACTION/WebMobAppS_InvoiceSaveUpdate",

            {
              method:"POST",
              'Content-Type':"application/json",
              body: JSON.stringify(data)
            }).then((res) => res.json()).then((response) => {
         
         console.log(response)
            localStorage. removeItem('client_id')
          localStorage. removeItem('client_Title')
          alert("Invoice Save Successfully !")
          setSpecialDiscountPer(0)
          setSpecialDiscountValue(0)
          setrfdSpecialDiscountPer(0)
          setrfdSpecialDiscountValue(0)
          setcount(0)
          setClientSelect(false)
          
          
dispatcchUserEvents("ClearData")
     
            })
          }
          else{
            alert("Please Select Products")

          }
           
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "12.5em",
             padding: "5px",
             height: "4em",
             marginLeft:"2%",
            //  borderRadius:30
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#268ae1"

           }}>
               Save Record
           </Button>
           </GridItem>
           </GridContainer>

        </div>
        
       
       


      </Paper>
     
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOpen(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Please Select a Client</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <MaterialTable
            columns={[
              { field: "select", title: "Select" ,
             },
              { field: "clientTitle", title: "Client's Title",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.clientTitle}</p>
              } },
              { field: "phone", title: "Phone",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.phone}</p>
              }},
              { field: "city", title: "City",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.city}</p>
              } },
              { field: "state", title: "State",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.state}</p>
              } },
              { field: "zip", title: "Zip",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.zip}</p>
              } },
              { field: "address", title: "Address",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.address}</p>
              } },
             



            ]}
            style={{
              // marginLeft:width<960?0: 10,
              border:" 2px solid #268ae1",
              
            }}
            options={{
              // paging: false,
               border:" 2px solid #268ae1",
              headerStyle: {
                backgroundColor: "#268ae1",
                color: "#FFF",
                // fontSize: "17px",
                // textAlign: "center",
                fontWeight: "bold",
                // minHeight:"50em"
              },
            
              // rowStyle: rowData => {
              //   // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
              //   //   return {};
              //   // }
              //   // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
              //   return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
              // };
              // }
            }}
            data={ClientList}
            // title={"Clients"}
            title={<span style={{ color: '#818285',fontWeight:"bold" }}>Clients</span>}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOpen(false)
              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={openImageView}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setopenImageView(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h3 className={classes.modalTitle}><b>View Image</b></h3>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
           <GridContainer style={{marginTop:"2%"}}>
                     <GridItem sm={12} md={12} lg={12} >
                     {imageloading?  <center>
            <ReactLoading type={'spinningBubbles'} color={'#196c90'} height={'10em'} width={'10em'} />
            <h3>Please Wait Image Loading....</h3>
          </center>:
                      <div style={{  display:"flex",justifyContent:"center",alignItems:"center",}}>
                        <img style={{height:250,width:250}} src={selectedReport}></img>
                     {/* <iframe  style={{alignSelf:"center"}}  src={selectedReport}  width={500} height={400}></iframe> */}
                     </div>}
                     </GridItem>
                     </GridContainer>


        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenImageView(false)
              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
      {/* foer qr code  */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'sm'}
        fullWidth={true}
        open={openQrCodeModal}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setopenQrCodeModal(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h3 className={classes.modalTitle}><b>View Image</b></h3>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
           <GridContainer style={{marginTop:"2%"}}>
                     <GridItem sm={12} md={12} lg={12} >
                    
                      <div style={{  display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                         {scanResult?<div><h3> success: </h3><h4>{scanResult}</h4>
                        {console.log("check it")}

                        {/* {console.log(scanResult)} */}
{autoAddItemIntoCart()}

                         </div>
                         
                         :<>
              <div id="reader">
                <h4>{scanResult}</h4>
             
              {/* <img src={mobile}></img> */}
                
                
              </div>
              <img src={mobile} height={150} width={75}></img>
              <Button style={{backgroundColor:"#268ae1",color:"white"}}
            onClick={() => {
              // 4-june-2024
              
              // rerenderQrScanner()
              // autoAddItemIntoCart()
              setscanResult("")
              setopenQrCodeModal(false)
             

            }}
            color="danger"
            simple
          >
            Continue To Scan 
                    </Button>
               
               </>
              }
              
               
           

                        {/* <img src={selectedReport}></img> */}
                     {/* <iframe  style={{alignSelf:"center"}}  src={selectedReport}  width={500} height={400}></iframe> */}
                     </div>
                     </GridItem>
                     </GridContainer>


        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setopenQrCodeModal(false)
              rerenderQrScanner()

              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
